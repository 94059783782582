import React, { useEffect, useState } from 'react'
import './css/style.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../../app/authSlice';
import globalLoading from '../../../redux/reducers/globalLoading';
import { toastError, toastSuccess } from '../../../helpers/toast';
import requestApi from '../../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../../app/profileSlice';
import { useTranslation } from 'react-i18next';



const Profile = () => {
  const { t } = useTranslation(['profile', 'notiError', 'notiSuccess', 'notiWarning']);
  const schema = yup.object().shape({
    username: yup.string().required(t('notiWarning:username_required')),
    first_name: yup.string().required(t('notiWarning:first_name_required')),
    last_name: yup.string().required(t('notiWarning:last_name_required')),
    // email: yup.string().required('Email is required.').matches(
    //   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    //   'Invalid email address'
    // ),
    email: yup.string().when({
      is: (exists) => !!exists,
      then: (schema) => schema.matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Invalid email address'
      ),
      otherwise: (schema) => schema.required(t('notiWarning:email_required')),
    }),
    phone_number: yup.string(),
    address: yup.string(),
    birthday: yup.string(),
    gender: yup.string(),
  });

  const schema2 = yup.object().shape({
    current_password: yup.string(),
    new_password: yup.string(),
    // confirm_password: yup.string().required('Confirm password is required.')
    //   .oneOf([yup.ref('new_password'), null], 'Confirm password does not match'),
    confirm_password: yup.string().when({
      is: (exists) => !!exists,
      then: (schema) => schema.oneOf([yup.ref('new_password'), null], t('notiWarning:confirm_password_not_match')),
      otherwise: (schema) => schema.required(t('notiWarning:confirm_password_required')),
    }),
  })
  const dispatch = useDispatch();
  const { register, setValue, control, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
  const { register: register2, handleSubmit: handleSubmitReset, formState: { errors: errors2 } } = useForm({ resolver: yupResolver(schema2) });
  const [birthday, setBirthday] = useState(new Date());
  const [idProfile, setIdProfile] = useState('');
  const [emailProfile, setEmailProfile] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getProfile()).then((result) => {
      // console.log(result);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // setUserData(result.payload.data.data);
        const fields = ['first_name', 'last_name', 'email', 'phone_number', 'username', 'avatar',
          'gender', 'address',
        ];
        fields.forEach((field) => setValue(field, result.payload.data.data[field]));
        setBirthday(result.payload.data.data.birthday ? new Date(result.payload.data.data.birthday) : '');
        setIdProfile(result.payload.data.data._id);
        setEmailProfile(result.payload.data.data.email);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log(error);
      toastError(error, 2000)
    })
  }, []);

  const handleSubmitFormUpdate = async (data) => {
    // console.log('Data input ===>', data);
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    data['birthday'] = birthday;
    // data['password'] = data.current_password;
    // console.log('Data format ===>', data);
    try {
      const result = await requestApi(`/user/${idProfile}`, 'PATCH', JSON.stringify(data));
      // console.log('Data result------>', result);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // toastSuccess('Account has updated successfully!!!', 1000);
      toastSuccess(t('notiSuccess:account_updated_success'), 1000);
    } catch (error) {
      console.log('error------->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (error.response && error.response.data.message === 'User-name-already-exists') {
        // toastError('Username already exists', 2000);
        toastError(t('notiError:username_exists'), 2000);
      } else {
        if (error.response && error.response.data.message === 'Email-already-exists') {
          // toastError('Email already exists', 2000);
          toastError(t('notiError:email_exists'), 2000);
        } else {
          // toastError('Update account failed', 2000);
          toastError(t('notiError:update_account_failed'), 2000);
        }
      }

    }

  }

  const handleFormResetPassword = async (data) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    const inputData = {
      id: idProfile,
      email: emailProfile,
      current_password: data.current_password,
      new_password: data.new_password
    }
    // console.log('reset password ---->', { data, inputData });
    dispatch(resetPassword(inputData)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // console.log("result---->", result);
      if (result.error && result.payload) {
        if (result.payload === 'Wrong credentials!!') {
          // toastError('Wrong password!!!', 2000)
          toastError(t('notiError:wrong_password'), 2000);
        } else {
          toastError(result.payload, 2000)
        }
      }
      if (result.payload.status && result.payload.status === 200) {
        // toastSuccess('The password has reset successfully!!!', 1000);
        toastSuccess(t('notiSuccess:password_reset_success'), 1000);
        setTimeout(() => {
          navigate('/admin/dashboard')
        }, 100);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-xl px-4 mt-4">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button className="nav-link active" id="nav-account-tab" data-bs-toggle="tab" data-bs-target="#nav-account" type="button" role="tab" aria-controls="nav-account" aria-selected="true">{t('profile:tab.account')}</button>
              <button className="nav-link" id="nav-password-tab" data-bs-toggle="tab" data-bs-target="#nav-password" type="button" role="tab" aria-controls="nav-password" aria-selected="false">{t('profile:tab.password')}</button>
            </div>
          </nav>
          <hr className="mt-0 mb-4" />
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-account" role="tabpanel" aria-labelledby="nav-account-tab">
              <div className="row">
                <div className="col-xl-4">
                  <div className="card mb-4 mb-xl-0">
                    <div className="card-header">
                      {/* Profile Picture */}
                      {t('profile:title.profile_picture')}
                    </div>
                    <div className="card-body text-center">
                      <div className="text-center">
                        {/* <img className="img-account-profile rounded-circle mb-2" src="http://bootdey.com/img/Content/avatar/avatar1.png" alt=""/> */}
                        <img src="//placehold.it/100" className="img-account-profile rounded-circle mb-2" alt="avatar" />
                        <h6>
                          {/* Upload a different photo... */}
                          {t('profile:form.upload_photo')}
                        </h6>
                        <div className="small font-italic text-muted mb-4">
                          {/* JPG or PNG no larger than 5 MB */}
                          {t('profile:form.no_larger')}
                        </div>
                        {/* <input type="file" className="form-control" /> */}
                        <button disabled className="btn btn-primary" type="button">
                          {/* Upload new image */}
                          {t('profile:button.upload_new_image')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className="card mb-4">
                    <div className="card-header">
                      {/* Account Details */}
                      {t('profile:title.account_detail')}
                    </div>
                    <div className="card-body">
                      <form >
                        <div className="mb-3">
                          <label className="small mb-1">
                            {/* Username */}
                            {t('profile:form.username')}
                          </label>
                          <input {...register("username")} className="form-control" type="text" placeholder="Enter your username" />
                          {errors.username && <p style={{ color: 'red' }}>{errors.username.message}</p>}
                        </div>
                        <div className="row gx-3 mb-3">
                          <div className="col-md-6">
                            <label className="small mb-1">
                              {/* Last name */}
                              {t('profile:form.last_name')}
                            </label>
                            <input {...register("last_name")} className="form-control" type="text" placeholder={t('profile:form.place_holder.enter_last_name')} />
                            {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name.message}</p>}
                          </div>
                          <div className="col-md-6">
                            <label className="small mb-1" >
                              {/* First name */}
                              {t('profile:form.first_name')}
                            </label>
                            <input {...register("first_name")} className="form-control" type="text" placeholder={t('profile:form.place_holder.enter_first_name')} />
                            {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name.message}</p>}
                          </div>
                        </div>
                        <div className="row gx-3 mb-3">
                          <div className="col-md-6">
                            <label className="small mb-1">Email</label>
                            <input {...register("email")} className="form-control" type="email" placeholder={t('profile:form.place_holder.enter_email')} />
                            {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                          </div>
                          <div className="col-md-6">
                            <label className="small mb-1">
                              {/* Phone Number */}
                              {t('profile:form.phone_number')}
                            </label>
                            <input {...register("phone_number")} className="form-control" type="text" placeholder={t('profile:form.place_holder.enter_phone_number')} />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="small mb-1">
                            {/* Address */}
                            {t('profile:form.address')}
                          </label>
                          <input {...register("address")} className="form-control" type="text" placeholder={t('profile:form.place_holder.enter_address')} />
                        </div>
                        <div className="row gx-3 mb-3">
                          <div className="col-md-6">
                            <label className="small mb-1">
                              {t('profile:form.gender')}
                            </label>
                            <select  {...register("gender")} style={{ height: '60%'}} className='form-select'>
                              <option value='Other'>{t('profile:form.select.other')}</option>
                              <option value='Male'>{t('profile:form.select.male')}</option>
                              <option value='Female'>{t('profile:form.select.female')}</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label className="small mb-1">
                              {/* Birthday */}
                              {t('profile:form.birthday')}
                            </label>
                            {/* <input className="form-control" type="text" name="birthday" placeholder="Enter your birthday" /> */}
                            <Controller
                              name='birthday'
                              control={control}
                              render={({ field }) => (
                                <span style={{ display: 'flex' }}>
                                  <DatePicker
                                    {...field}
                                    className='form-control example-custom-input'
                                    selected={birthday}
                                    onChange={(date) => setBirthday(date)}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    scrollableYearDropdown={false}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    todayButton="TODAY"
                                  />
                                </span>
                              )}
                            />
                          </div>
                        </div>
                        {/* <button className="btn btn-success" onClick={handleSubmit(handleSubmitFormUpdate)} type="button">Save changes</button> */}
                        <button onClick={handleSubmit(handleSubmitFormUpdate)} type='button' className='mt-4 btn btn-success'>{t('profile:button.save_change')}</button>
                        {/* <button className="btn btn-danger" style={{ marginLeft: '10px' }} type="button">Cancel</button> */}
                        <Link to={'/admin/dashboard'} style={{ marginLeft: '10px' }} className='mt-4 btn btn-danger' >{t('profile:button.cancel')}</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade " id="nav-password" role="tabpanel" aria-labelledby="nav-password-tab">
              <div className="col-lg-8">
                <div className="card mb-4">
                  <div className="card-header">
                    {/* Change Password */}
                    {t('profile:title.change_password')}
                  </div>
                  <div className="card-body">
                    <form >
                      <div className="mb-3">
                        <label className="small mb-1">
                          {/* Current Password */}
                          {t('profile:form.current_pass')}
                        </label>
                        <input {...register2("current_password")} className="form-control" type="password" placeholder={t('profile:form.place_holder.enter_current_pass')} />
                      </div>
                      <div className="mb-3">
                        <label className="small mb-1">
                          {/* New Password */}
                          {t('profile:form.new_pass')}
                        </label>
                        <input {...register2("new_password")} className="form-control" type="password" placeholder={t('profile:form.place_holder.enter_new_pass')} />
                      </div>
                      <div className="mb-3">
                        <label className="small mb-1">
                          {/* Confirm Password */}
                          {t('profile:form.confirm_pass')}
                        </label>
                        <input {...register2("confirm_password")} className="form-control" type="password" placeholder={t('profile:form.place_holder.confirm_new_pass')} />
                        {errors2.confirm_password && <p style={{ color: 'red' }}>{errors2.confirm_password.message}</p>}
                      </div>
                      <div className='mb-3'>
                        <Link> <label>reset</label></Link>
                      </div>
                      <button onClick={handleSubmitReset(handleFormResetPassword)} className="btn btn-success" type="button">{t('profile:button.save_change')}</button>
                      <Link to={'/admin/dashboard'} style={{ marginLeft: '10px' }} className='btn btn-danger' >{t('profile:button.cancel')}</Link>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>



  )
}

export default Profile