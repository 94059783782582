import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import '../../assets/css/styles.css'
import { useNavigate } from 'react-router-dom'
import globalLoading from '../../../../redux/reducers/globalLoading'
import { getOnePerson } from '../../../../app/personSlice'
import { toastError } from '../../../../helpers/toast'
import TreeNode from '../TreeNode'
import { selectedPerson } from '../Slice/genealogySlice'

const Tree = ({ treeData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  function handleItemClick(itemId) {
    setSelectedItem(itemId);
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getOnePerson(itemId))
      .then((result) => {
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        if (result.error && result.payload) {
          toastError(result.payload, 2000);
        }
        if (result.payload.status && result.payload.status === 200) {
          dispatch(selectedPerson(result.payload.data.data.item));
          navigate('/member/member-detail');
        }
      })
      .catch((error) => {
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        console.log('error-->', error);
        toastError(error, 2000);
      });
  };
  return (
    <div className='root-tree'>
      <TreeNode
        items={treeData}
        selectedItem={selectedItem}
        onItemClick={handleItemClick}
      />
    </div>
  );
}

export default Tree