import React, { useState } from 'react'

const TreeNode = ({ items, selectedItem, onItemClick }) => {
  const [checked, setChecked] = useState(true);
  return (
    <ul className='tree'>
      {
        items.map((item) => (
          <li id={item._id} key={item._id}>
            {(item.children && item.children.length > 0) ? (
              <>
                <input
                  defaultChecked={checked}
                  onChange={() => setChecked((state) => !state)}
                  id={item._id}
                  type="checkbox" />
                <label
                  className="tree-label"
                  htmlFor={item._id}
                  style={{
                    background:
                      selectedItem === item._id ? 'yellow' : '',
                  }}
                  onClick={() => onItemClick(item._id)}
                >
                  {item.last_name}  {item.first_name} ({item.descendants})
                </label>
                <TreeNode
                  items={item.children}
                  selectedItem={selectedItem}
                  onItemClick={onItemClick}
                />
              </>
            ) : (
              <span
                className='tree-label'
                htmlFor={item._id}
                style={{
                  background:
                    selectedItem === item._id ? 'yellow' : '',
                }}
                onClick={() => onItemClick(item._id)}
              >
                {item.last_name}  {item.first_name} ({item.descendants})
              </span>
            )}
          </li>
        ))
      }
    </ul >
  );
};

export default TreeNode