import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestApi from "../api/api";

export const resetPassword = createAsyncThunk('profile/resetPassword',
  async ({ id, email, current_password, new_password }, thunkAPI) => {
    try {
      const response = await requestApi(`/auth/reset-password/${id}`, 'PATCH', { id, email, current_password, new_password });
      return response;
    } catch (error) {
      console.log('error Slice-->', error);
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: false,
    isSuccess: false,
    errorMessage: null,
    profileInfo: {}
  },
  reducers: {},
  extraReducers: {
    [resetPassword.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [resetPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('rejected----->', { state, action });
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.profileInfo = action.payload.data;
      // console.log('fulfilled---->', { state, action });
    }
  }
});

const { reducer: profileReducer } = profileSlice;
export default profileReducer;