import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux';
import globalLoading from '../redux/reducers/globalLoading';
import { loginAccount } from '../app/authSlice';
import { useTranslation } from 'react-i18next';
import { toastError } from '../helpers/toast';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation(['profile', 'button', 'title', 'notiError', 'notiSuccess', 'notiWarning']);
  const onChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (isSubmitted) {
      validateForm();
    }
  }, [loginData])

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (loginData.email === '' || loginData.email === undefined) {
      // errors.email = "Please enter email"
      errors.email = t('notiWarning:please_enter_email');
    } else {
      let valid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(loginData.email)
      if (!valid) {
        // errors.email = "Invalid email address"
        errors.email = t('notiWarning:invalid_email_address')
      }
    }

    if (loginData.password === '' || loginData.password === undefined) {
      // errors.password = "Please enter password"
      errors.password = t('notiWarning:please_enter_password')
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      isValid = false;
    } else {
      setFormErrors({});
    }

    return isValid;
  }

  const onSubmit = () => {

    let valid = validateForm();

    if (valid) {
      // console.log("request login api")
      dispatch(globalLoading.actions.CONTROL_LOADING(true));

      dispatch(loginAccount(loginData)).then((result) => {
        // console.log('result------>', result);
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
        if (result.error) {
          if (result.payload && result.payload === 'Wrong credentials!!') {
            // toast.error('Your email or password is incorrect', { position: "top-center" })
            toastError(t('notiError:email_password_incorrect'), 2000)
          } else {
            // toast.error("Server is down. Please try again!", { position: "top-center" })
            toastError('Server is down. Please try again!', 2000)
          }
        }
        if (result.payload.status === 201) {
          navigate('/admin/dashboard');
        }
      }).catch((err) => {
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        // toast.error(err, { position: "top-center" })
        toastError(err, 2000)
      });

      // requestApi('/auth/login', 'POST', loginData).then((res) => {
      // console.log('hung--->',res.data)
      //   localStorage.setItem('access_token', res.data.access_token);
      //   localStorage.setItem('refresh_token', res.data.refresh_token);
      // document.cookie = "token=" + res.data.access_token;
      //   dispatch(globalLoading.actions.CONTROL_LOADING(false))
      //   navigate('/admin/dashboard');
      // }).catch(err => {
      //   dispatch(globalLoading.actions.CONTROL_LOADING(false))
      //   console.log(err);
      //   if (typeof err.response !== "undefined") {
      //     if (err.response.status !== 201) {
      //       toast.error(err.response.data.message, { position: "top-center" })
      //     }
      //   } else {
      //     toast.error("Server is down. Please try again!", { position: "top-center" })
      //   }
      // })
    }

    setIsSubmitted(true);
  }

  return (
    <div id="layoutAuthentication" className='bg-primary'>
      <div id="layoutAuthentication_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header"><h3 className="text-center font-weight-light my-4">
                    {/* Login */}
                    {t('title:login')}
                  </h3></div>
                  <div className="card-body">
                    <form>
                      <div className="form-floating mb-3">
                        <input className="form-control" type="email" name='email' onChange={onChange} placeholder="name@example.com" />
                        <label>
                          {/* Email address */}
                          {t('profile:form.place_holder.email_address')}
                        </label>
                        {formErrors.email && <p style={{ color: 'red' }}>{formErrors.email}</p>}
                      </div>
                      <div className="form-floating mb-3">
                        <input className="form-control" name='password' onChange={onChange} type="password" placeholder="Password" />
                        <label>
                          {/* Password */}
                          {t('profile:form.place_holder.password')}
                        </label>
                        {formErrors.password && <p style={{ color: 'red' }}>{formErrors.password}</p>}
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                        <Link className="small" to='/admin/forgot-password'>
                          {/* Forgot Password? */}
                          {t('profile:button.forgot_password')}
                        </Link>
                        <button className="btn btn-primary" type='button' onClick={onSubmit} >{t('button:login')}</button>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center py-3">
                    <div className="small">
                      <Link to='/admin/register'>
                        {/* Need an account? Sign up! */}
                        {t('profile:button.need_account')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div id="layoutAuthentication_footer">
        <footer className="py-4 bg-light mt-auto">
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              {/* <div className="text-muted">Copyright &copy; Your Website 2023</div> */}
              {/* <div>
                <a href="/#">Privacy Policy</a>
                &middot;
                <a href="/#">Terms &amp; Conditions</a>
              </div> */}
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Login