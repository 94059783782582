import React, { useEffect, useState } from 'react'
import requestApi from '../api/api';
import { Button, Form, Modal, } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import globalLoading from '../redux/reducers/globalLoading';
import { getGenealogyName, updateGenealogyName } from '../app/dashboardSlice';
import { toastError, toastSuccess } from '../helpers/toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const buttonTitle = {
  'fontSize': '30px',
  'minWidth': '10em',
}
const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [genealogyName, setGenealogyName] = useState([]);
  const [countName, setCountName] = useState(0);
  const [onChangeNameData, setOnChangeNameData] = useState('');
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(Date.now());
  const { t } = useTranslation(['title', 'button', 'notiSuccess', 'notiError']);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalSpouses, setTotalSpouses] = useState(0);
  const [totalOthers, setTotalOthers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  useEffect(() => {
    requestApi('/person/search', 'GET')
      .then(response => {
        setTotalMembers(response.data ? response.data.person.count : 0)
      })
      .catch(error => {
        console.log('error', error);
      })

    requestApi('/marriage-person/search', 'GET')
      .then(response => {
        setTotalSpouses(response.data ? response.data.result.count : 0)
      })
      .catch(error => {
        console.log('error', error);
      })

    requestApi('/other-people/search', 'GET')
      .then(response => {
        setTotalOthers(response.data ? response.data.result.count : 0)
      })
      .catch(error => {
        console.log('error', error);
      })

    requestApi('/user', 'GET')
      .then(response => {
        setTotalUsers(response.data ? response.data.data.count : 0)
      })
      .catch(error => {
        console.log('error', error);
      })
  }, [])

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getGenealogyName()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('Genealogy Name--->', result.payload.data.data.items[0]);
        setGenealogyName(result.payload.data.data.items);
        setCountName(result.payload.data.data.count);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, [refresh]);

  const handleShowModal = () => {
    setShowModal(true);
  }
  const handleChange = (e) => {
    const data = e.target.value;
    // console.log('onChange-->', data);
    setOnChangeNameData(data);
  }
  const handleUpdateName = () => {
    const inputData = {
      id: countName > 0 ? genealogyName[countName - 1]._id : '',
      name: onChangeNameData
    }
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(updateGenealogyName(inputData)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // toastSuccess('Successfully updated genealogy name!', 1000);
        toastSuccess(t('notiSuccess:updated_genealogy_success'), 1000);
        setShowModal(false)
        setRefresh(Date.now())
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      setShowModal(false);
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <div className="row mt-4 mb-4">
            <div className="col-md-12 text-center">
              <div className="btn-group">
                <button disabled className='btn btn-secondary' style={buttonTitle} >
                  {countName > 0 ? genealogyName[countName - 1].name : ''}
                </button>
                <button type="button" className="btn btn-lg btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="visually-hidden"></span>
                </button>
                <ul className="dropdown-menu">
                  <li><button className="dropdown-item" onClick={handleShowModal}> {t('button:edit')}</button></li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="card text-white border-primary mb-4">
                <div className="card-body bg-primary">
                  <div className='row'>
                    <div className="col-3">
                      <i className="fa fa-users fa-5x"></i>
                    </div>
                    <div className="col-9 text-end">
                      <div className="huge">{totalMembers}</div>
                      <div> {t('title:total_members')}</div>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link to='/admin/persons' className="small text-black stretched-link">
                    {t('title:view_details')}
                  </Link>
                  <div className="small text-primary"><i className="fa fa-arrow-circle-right"></i></div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card text-white border-success mb-4">
                <div className="card-body bg-success">
                  <div className='row'>
                    <div className="col-3">
                      <i className="fas fa-people-arrows fa-5x"></i>
                    </div>
                    <div className="col-9 text-end">
                      <div className="huge">{totalSpouses}</div>
                      <div> {t('title:total_spouses')}</div>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link to='/admin/spouse' className="small text-black stretched-link">
                    {t('title:view_details')}
                  </Link>
                  <div className="small text-success"><i className="fa fa-arrow-circle-right"></i></div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card text-white border-success mb-4">
                <div className="card-body bg-info">
                  <div className='row'>
                    <div className="col-3">
                      <i className="fas fa-user-friends fa-5x"></i>
                    </div>
                    <div className="col-9 text-end">
                      <div className="huge">{totalOthers}</div>
                      <div> {t('title:total_others')}</div>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link to='/admin/others' className="small text-black stretched-link">
                    {t('title:view_details')}
                  </Link>
                  <div className="small text-info"><i className="fa fa-arrow-circle-right"></i></div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="card text-white border-danger mb-4">
                <div className="card-body bg-danger">
                  <div className='row'>
                    <div className="col-3">
                      <i className="fas fa-user-circle fa-5x"></i>
                    </div>
                    <div className="col-9 text-end">
                      <div className="huge">
                        {totalUsers}
                      </div>
                      <div> {t('title:total_users')}</div>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <Link to='/admin/users' className="small text-black stretched-link">
                    {t('title:view_details')}
                  </Link>
                  <div className="small text-danger"><i className="fa fa-arrow-circle-right"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main >
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('title:edit_genealogy_name')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t('title:genealogical_name')}</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Gia phả..."
                autoFocus
                onChange={handleChange}
                defaultValue={countName > 0 ? genealogyName[countName - 1].name : ''}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('button:close')}
          </Button>
          <Button variant="primary" onClick={handleUpdateName}>
            {t('button:save_change')}
          </Button>
        </Modal.Footer>
      </Modal>

    </div >
  )
}

export default Dashboard