import React, { useEffect, useState } from 'react'
import '../../assets/css/styles.css'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import globalLoading from '../../../../redux/reducers/globalLoading'
import { getGenealogyName } from '../../../../app/dashboardSlice'
import { toastError } from '../../../../helpers/toast'
import { useTranslation } from 'react-i18next'
import { languages } from '../../../../i18n/i18n'

const buttonTitle = {
  'minWidth': '10em',
}
const flagImg = {
  height: "30px",
  marginRight: "10px"
}
const Header = () => {
  const [genealogyName, setGenealogyName] = useState([]);
  const [countName, setCountName] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['user']);
  const [currentLanguage, setCurrentLanguage] = useState(languages[localStorage.getItem('i18nextLng') || 'vi'].title);
  const [flagCountry, setFlagCountry] = useState(languages[localStorage.getItem('i18nextLng') || 'vi'].flag);

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getGenealogyName()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        setGenealogyName(result.payload.data.data.items);
        setCountName(result.payload.data.data.count);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, []);

  const handleSelectedLanguage = (locale, country, flag) => {
    i18n.changeLanguage(locale);
    localStorage.setItem("i18nextLng", locale);
    setCurrentLanguage(country)
    setFlagCountry(flag);
  }

  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-secondary">
      <Link to='/' className="navbar-brand ps-4" style={buttonTitle}>
        {countName > 0 ? genealogyName[countName - 1].name : ''}
      </Link>
      <div className="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown">
            <div className="nav-link dropdown-toggle"
              id="dropdown03" data-toggle="dropdown" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {/* Phả hệ */}
              {t('user:header.genealogy')}
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdown03">
              <li>
                <Link className="dropdown-item" to='/member-list'>
                  {/* Phả hệ */}
                  {t('user:header.genealogy')}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to='/member/member-detail'>
                  {/* Thông tin thành viên */}
                  {t('user:header.member_info')}
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to='/announcements'>
              {t('user:header.announcements_news')}
            </Link>
          </li>
          {/* <li className="nav-item ">
            <a className="nav-link" href="http://truongs.info/news">
              Thông báo / Tin tức
            </a>
          </li> */}

          {/* <li className="nav-item dropdown
          ">
            <a className="nav-link dropdown-toggle" href="http://truongs.info/discuss"
              id="dropdown03" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Thảo luận
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdown03">
              <a className="dropdown-item" href="http://truongs.info/discuss">
                Thảo luận
              </a>
              <a className="dropdown-item" href="http://truongs.info/group-memory">
                Hồi ký
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/2664">
                Trương Quang Cảm
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/4053">
                Trương Nguyễn Lệ Hằng
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/4050">
                Trương Nguyễn Thanh Huyền
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/0">
                Admin
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/5202">
                Trương Quang Tuân
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/2667">
                Trương Quang Liên
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/5143">
                Trương Quang Trình
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/4080">
                Trương Quang Thọ
              </a>
              <a className="dropdown-item" href="http://truongs.info/memory/3361">
                Trương Quang Duy
              </a>
            </div>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="http://truongs.info/pedigree-old" target="_blank">
              Gia phả cũ phiên bản 2006
            </a>
          </li> */}
        </ul>
      </div>
      <div className="nav-item dropdown px-md-3">
        <span className="nav-link dropdown-toggle" style={{ marginRight: '20px' }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <img
            src={flagCountry}
            className="img-thumbnail"
            style={flagImg}
            alt="Flag" />
          {currentLanguage}
        </span>
        <ul className="dropdown-menu">
          <li className='cursor-pointer'>
            <div className='dropdown-item' onClick={() => handleSelectedLanguage(languages['vi'].language, languages['vi'].title, languages['vi'].flag)}>
              <img
                src={languages['vi'].flag}
                className="img-thumbnail"
                style={flagImg}
                alt="vi-img" />
              <span>{languages['vi'].title}</span>
            </div>
          </li>
          <li className='cursor-pointer'>
            <div className='dropdown-item' onClick={() => handleSelectedLanguage(languages['en'].language, languages['en'].title, languages['en'].flag)}>
              <img
                src={languages['en'].flag}
                className="img-thumbnail"
                style={flagImg}
                alt="us-img" />
              <span>{languages['en'].title}</span>
            </div>
          </li>
        </ul>
      </div>
    </nav>

  )
}

export default Header