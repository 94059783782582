// const inititalState = {
//     status: false
// }

// const globalLoading = (state = inititalState, action) => {
//     switch (action.type) {
//         case "CONTROL_LOADING":
//             state = {
//                 status: action.status
//             }
//             return state;
//         default:
//             return state;
//     }
// }

// export default globalLoading;
import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
    name: 'globalLoading',
    initialState: {
        status: false
    },
    reducers: {
        CONTROL_LOADING: (state, action) => {
            state.status = action.payload
        }
    }
})