import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { getProfile } from '../../app/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { languages } from '../../i18n/i18n';


const flagImg = {
  height: "30px",
  marginRight: "10px"
}

const Header = () => {
  const { t, i18n } = useTranslation(['headerAdmin']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState(languages[localStorage.getItem('i18nextLng') || 'vi'].title);
  const [flagCountry, setFlagCountry] = useState(languages[localStorage.getItem('i18nextLng') || 'vi'].flag);

  const onHandleLogout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    navigate("/admin/login")
  }

  useEffect(() => {
    const getProfileUser = async () => {
      const res = await dispatch(getProfile());
      const currentUser = unwrapResult(res);
      const data = currentUser.data.data;
      setFullName(data.full_name);
    }
    getProfileUser();
  }, []);

  const handleSelectedLanguage = (locale, country, flag) => {
    i18n.changeLanguage(locale);
    localStorage.setItem("i18nextLng", locale);
    setCurrentLanguage(country)
    setFlagCountry(flag);
  }


  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark fixed-top bg-dark">
      {/* <!-- Navbar Brand--> */}
      <a className="navbar-brand ps-4 fs-4" href="/admin/dashboard">Genealoy</a>
      {/* <!-- Sidebar Toggle--> */}
      {/* <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button> */}
      {/* <!-- Navbar Search--> */}
      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        {/* <div className="input-group">
          <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
          <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
        </div> */}
      </form>
      {/* <!-- Navbar--> */}
      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">

        <li className="nav-item dropdown">
          <span className="nav-link dropdown-toggle" style={{ marginRight: '20px' }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img
              src={flagCountry}
              className="img-thumbnail"
              style={flagImg}
              alt="Flag" />
            {currentLanguage}
          </span>
          <ul className="dropdown-menu">
            <li className='cursor-pointer'>
              <div className='dropdown-item' onClick={() => handleSelectedLanguage(languages['vi'].language, languages['vi'].title, languages['vi'].flag)}>
                <img
                  src={languages['vi'].flag}
                  className="img-thumbnail"
                  style={flagImg}
                  alt="vi-img" />
                <span>{languages['vi'].title}</span>
              </div>
            </li>
            <li className='cursor-pointer'>
              <div className='dropdown-item' onClick={() => handleSelectedLanguage(languages['en'].language, languages['en'].title, languages['en'].flag)}>
                <img
                  src={languages['en'].flag}
                  className="img-thumbnail"
                  style={flagImg}
                  alt="us-img" />
                <span>{languages['en'].title}</span>
              </div>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{ marginRight: '30px' }} id="navbarDropdown" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i><label style={{ color: 'while', marginLeft: '10px' }}>{fullName}</label></a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li> <Link to='/admin/profile' className="dropdown-item">
              {/* Edit profile */}
              {t('headerAdmin:edit_profile')}
            </Link></li>
            {/* <li><a className="dropdown-item" href="#!">Activity Log</a></li> */}
            <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item" href="/admin/login" onClick={onHandleLogout}>
              {/* Logout */}
              {t('headerAdmin:logout')}
            </a></li>
          </ul>
        </li>
      </ul>
    </nav>
  )
}

export default Header