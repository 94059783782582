import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestApi from "../api/api";

export const getOnePerson = createAsyncThunk('person/getOnePerson', async (param, thunkAPI) => {
  try {
    const response = await requestApi(`/person/${param}`, 'GET');
    return response;
  } catch (error) {
    console.log('error-------->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
})

export const searchPerson = createAsyncThunk('person/searchPerson',
  async ({ name, phone, email, address, limit = 100, page = 1 }, thunkAPI) => {
    try {
      // console.log('searchPerson-------->', { name, phone, email, address });
      let query = `?limit=${limit}&page=${page}&name=${name}&phone=${phone}&email=${email}&address=${address}`
      const response = await requestApi(`/person/search${query}`, 'GET');
      // console.log('searchPerson-------->', response);
      return response;
    } catch (error) {
      console.log('error-------->', error);
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  });

export const personPedigree = createAsyncThunk('person/genealogy', async (param, thunkAPI) => {
  try {
    const response = await requestApi('/person/genealogy', 'GET');
    return response;
  } catch (error) {
    console.log('error-------->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
})

const personSlice = createSlice({
  name: 'person',
  initialState: {
    personInfo: {},
    listPersons: [],
    isLoading: false,
    errorMessage: null,
  },
  reducers: {},
  extraReducers: {
    [getOnePerson.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      // console.log('getOnePerson pending------>', { state, action });
    },
    [getOnePerson.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('getOnePerson rejected------>', { state, action });
    },
    [getOnePerson.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.personInfo = action.payload.data;
      // console.log('getOnePerson fulfilled------>', action.payload.data);
    },

    [searchPerson.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      // console.log('searchPerson pending------>', { state, action });
    },
    [searchPerson.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('searchPerson rejected------>', { state, action });
    },
    [searchPerson.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listPersons = action.payload.data;
      // console.log('searchPerson fulfilled------>', { state, action });
    },
    [personPedigree.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [personPedigree.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    [personPedigree.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listPersons = action.payload.data;
    }


  }
})

const { reducer: personReducer } = personSlice;
export default personReducer;