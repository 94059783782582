import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
  const { t } = useTranslation(['profile', 'button', 'title', 'notiError', 'notiSuccess', 'notiWarning']);

  return (
    <div id="layoutAuthentication" className='bg-primary'>
      <div id="layoutAuthentication_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header"><h3 className="text-center font-weight-light my-4">
                    {/* Password Recovery */}
                    {t('title:password_recovery')}
                  </h3></div>
                  <div className="card-body">
                    <div className="small mb-3 text-muted">
                      {/* Enter your email address and we will send you a link to reset your password. */}
                      {t('notiWarning:send_you_new_pass')}
                    </div>
                    <form>
                      <div className="form-floating mb-3">
                        <input className="form-control" id="inputEmail" type="email" placeholder="name@example.com" />
                        <label htmlFor="inputEmail">
                          {/* Email address */}
                          {t('profile:form.place_holder.email_address')}
                        </label>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                        <Link className="small" to='/admin/login'>
                          {/* Return to login */}
                          {t('profile:button.return_login')}
                        </Link>
                        <a className="btn btn-primary" href="login.html">
                          {/* Reset Password */}
                          {t('profile:button.reset_password')}
                        </a>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center py-3">
                    <div className="small">
                      <Link to='/admin/register'>
                        {/* Need an account? Sign up! */}
                        {t('profile:button.need_account')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div id="layoutAuthentication_footer">
        <footer className="py-4 bg-light mt-auto">
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              {/* <div className="text-muted">Copyright &copy; Your Website 2021</div> */}
              {/* <div>
                <a href="#">Privacy Policy</a>
                &middot;
                <a href="#">Terms &amp; Conditions</a>
              </div> */}
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default ForgotPassword