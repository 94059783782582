import React from 'react'
import Pedigree from './Pedigree'
import MemberSearch from './MemberSearch'
import MemberDetail from './MemberDetail'

const MemberInfo = () => {
  return (
    <main>
      <div className="p-4">
        <div className="row mt-3">
          <Pedigree />
          <div className="col-md-8 md-2">
            <div className="row">
              <MemberSearch />
              <MemberDetail />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default MemberInfo