import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const addSpouse = createAsyncThunk('spouseForm/addSpouse', async (spouse, thunkAPI) => {
  if (spouse) {
    return spouse;
  }
  return thunkAPI.rejectWithValue(null);
})

export const editSpouse = createAsyncThunk('spouseForm/editSpouse', async (spouse, thunkAPI) => {
  if (spouse) {
    return spouse;
  }
  return thunkAPI.rejectWithValue(null);
})

const spouseFormSlice = createSlice({
  name: 'spouseForm',
  initialState: {
    listSpouse: [],
    isLoading: false,
    errorMessage: null,
    spouseId: 0,
  },
  reducers: {},
  extraReducers: {
    [addSpouse.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [addSpouse.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    [addSpouse.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listSpouse = [...state.listSpouse, action.payload];
      // console.log('addSpouse fulfilled------>', action.payload);
    },

    [editSpouse.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [editSpouse.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    [editSpouse.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listSpouse = action.payload
      // console.log('editSpouse fulfilled------>', action.payload);
    },
  }
})
const { reducer: spouseFormReducer } = spouseFormSlice;
export default spouseFormReducer;