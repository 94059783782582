import axios from "axios";

export default async function requestApi(endpoint, method, body = [], responseType = 'json') {
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    "Access-Control-Allow-Origin": "*"
  }
  const instance = axios.create({ headers });
  // const url = `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_SERVER_PORT}`;
  const url = `${process.env.REACT_APP_HOST}`;

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token')
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config;
    },
    (error) => {
      return Promise.reject(error)
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalConfig = error.config;
      console.log("Access token expired")
      if (error.response && error.response.data.statusCode === 401) {
        try {
          console.log("call refresh token api")
          const result = await axios.post(`${url}/auth/refresh`, {},
            {
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${localStorage.getItem('refresh_token')}`
              },
            }
          );

          // const { access_token, refresh_token } = result.data;
          const access_token = result.data.access_token;
          localStorage.setItem('access_token', access_token)
          // localStorage.setItem('refresh_token', refresh_token)
          originalConfig.headers['Authorization'] = `Bearer ${access_token}`

          return instance(originalConfig)
        } catch (err) {
          // console.log("callback login page 1",err.response.data.statusCode)
          if (err.response && err.response.data.statusCode === 401) {
            console.log("callback login page")
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            window.location.href = '/admin/login'
          }
          return Promise.reject(err)
        }
      }
      return Promise.reject(error)
    }
  )



  return await instance.request({
    method: method,
    url: `${url}${endpoint}`,
    data: body,
    responseType: responseType
  })
}