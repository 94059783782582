import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Tree from './Tree';
import globalLoading from '../../../../redux/reducers/globalLoading';
import { personPedigree } from '../../../../app/personSlice';
import { toastError } from '../../../../helpers/toast';
import '../../assets/css/styles.css'

const Genealogy = () => {
  const [personData, setPersonData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(personPedigree()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('result person genealogy--->', result.payload.data.data.items);
        setPersonData(result.payload.data.data.items);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, []);
  return (
    <main>
      <div className="container mt-5">
        <div className='row mt-3'>
          <div className='root-genealogy  col-12 border rounded'>
            <div className='root-tree'>
              <Tree treeData={personData} />
            </div>
          </div>
        </div>
      </div>
    </main>

  )
}

export default Genealogy