import React from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import globalLoading from '../redux/reducers/globalLoading';
import { toastError, toastSuccess } from '../helpers/toast';
import { registerAccount } from '../app/authSlice';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { t } = useTranslation(['profile', 'button', 'title', 'notiError', 'notiSuccess', 'notiWarning']);

  const handleCreateAccounut = async (data) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    const formatData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
    }
    dispatch(registerAccount(formatData)).then((result) => {
      // console.log('result -------->', result);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        if (result.payload === 'Email-already-exists') {
          // toastError('Email already exists', 2000)
          toastError(t('notiError:email_exists'), 2000)
        } else {
          toastError(result.payload, 2000)
        }
      }
      if (result.payload.status && result.payload.status === 201) {
        // toastSuccess('Account has created successfully!!!', 2000);
        toastSuccess(t('notiSuccess:account_created_success'), 2000);
        setTimeout(() => {
          navigate('/admin/login')
        }, 100);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // toast.error(error, { position: "top-center" })
      toastError(error, 2000)
    });
    // try {
    //   const result = await requestApi('/auth/register', 'POST', formatData);
    //   console.log('result------>', result);
    //   dispatch(globalLoading.actions.CONTROL_LOADING(false));
    //   toastSuccess('Account has created successfully!!!', 2000);
    //   setTimeout(() => {
    //     navigate('/admin/login')
    //   }, 3000);
    // } catch (error) {
    //   console.log('error------->', error.response.data.message);
    //   dispatch(globalLoading.actions.CONTROL_LOADING(false));
    //   if (error.response.data.message === 'Email-already-exists') {
    //     toastError('Email already exists', 2000);
    //   } else {
    //     toastError('Create new account failed', 2000);
    //   }
    // }
  }
  return (
    <div id="layoutAuthentication" className='bg-primary'>
      <div id="layoutAuthentication_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header"><h3 className="text-center font-weight-light my-4">
                    {/* Create Account */}
                    {t('title:create_account')}
                  </h3></div>
                  <div className="card-body">
                    <form>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input {...register("last_name", { required: t('notiWarning:last_name_required') })} className="form-control" id="inputLastName" type="text" placeholder={t('profile:form.place_holder.enter_last_name')} />
                            <label htmlFor="inputLastName">
                              {/* Last name */}
                              {t('profile:form.last_name')}
                            </label>
                            {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name.message}</p>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3 mb-md-0">
                            <input {...register("first_name", { required: t('notiWarning:first_name_required') })} className="form-control" id="inputFirstName" type="text" placeholder={t('profile:form.place_holder.enter_first_name')} />
                            <label htmlFor="inputFirstName">
                              {/* First name */}
                              {t('profile:form.first_name')}
                            </label>
                            {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name.message}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="form-floating mb-3">
                        <input {...register("email",
                          {
                            required: t('notiWarning:email_required'),
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Invalid email address'
                            }
                          })}

                          className="form-control" id="inputEmail" type="email" placeholder="name@example.com" />
                        <label htmlFor="inputEmail">
                          {/* Email address */}
                          {t('profile:form.place_holder.email_address')}
                        </label>
                        {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="form-floating mb-3 mb-md-0">
                            <input {...register("password", { required: t('notiWarning:password_required') })} className="form-control" id="inputPassword" type="password" placeholder="Create a password" />
                            <label htmlFor="inputPassword">
                              {/* Password */}
                              {t('profile:form.place_holder.password')}
                            </label>
                            {errors.password && <p style={{ color: 'red' }}>{errors.password.message}</p>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3 mb-md-0">
                            <input  {...register("confirm_password", {
                              required: t('notiWarning:confirm_password_required'),
                              validate: (val) => {
                                if (watch('password') !== val) {
                                  return t('notiWarning:password_not_match');
                                }
                              },
                            })}
                              className="form-control" id="inputPasswordConfirm" type="password" placeholder="Confirm password" />
                            <label htmlFor="inputPasswordConfirm">
                              {/* Confirm Password */}
                              {t('profile:form.place_holder.confirm_pass')}
                            </label>
                            {errors.confirm_password && <p style={{ color: 'red' }}>{errors.confirm_password.message}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 mb-0">
                        {/* <div className="d-grid"><a className="btn btn-primary btn-block" href="login.html">Create Account</a></div> */}
                        <div className="d-grid">
                          <button onClick={handleSubmit(handleCreateAccounut)} type='button' className="btn btn-primary btn-block">{t('button:create_account')}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center py-3">
                    <div className="small"><Link to='/admin/login'>
                      {/* Have an account? Go to login */}
                      {t('profile:button.go_to_login')}
                    </Link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div id="layoutAuthentication_footer">
        <footer className="py-4 bg-light mt-auto">
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              {/* <div className="text-muted">Copyright &copy; Your Website 2023</div> */}
              {/* <div>
                <a href="/#">Privacy Policy</a>
                &middot;
                <a href="/#">Terms &amp; Conditions</a>
              </div> */}
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Register