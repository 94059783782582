import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DataTable from '../../../components/common/DataTable'
import { Button, Modal } from 'react-bootstrap'
import globalLoading from '../../../redux/reducers/globalLoading';
import moment from "moment";
import { Link } from 'react-router-dom'
import requestApi from '../../../api/api';
import { formatDate, formatDateTime } from '../../../helpers/common';
import { useTranslation } from 'react-i18next';

const PersonList = () => {
  const dispatch = useDispatch();
  const [persons, setPersons] = useState([])
  const [numOfPage, setNumOfPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0);
  const [searchString, setSearchString] = useState('')
  const [selectedRows, setSelectedRows] = useState([])

  const [deleteItem, setDeleteItem] = useState(null)
  const [deleteType, setDeleteType] = useState('single')
  const [deleteMulti, setDeleteMulti] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [refresh, setRefresh] = useState(Date.now())
  const { t } = useTranslation(['title', 'table', 'sidebar', 'button']);

  const columns = [
    {
      name: t('table:table.index'),
      // element: false,
    },
    {
      // name: "Last name",
      name: t('table:table.last_name'),
      element: row => row.extra_info.last_name
    },
    {
      // name: "First name",
      name: t('table:table.first_name'),
      element: row => row.extra_info.first_name
    },
    {
      name: "Email",
      element: row => row.email
    },
    {
      name: t('table:table.spouse'),
      element: row => row.marriage_person ? row.marriage_person.length : 0,
    },
    {
      name: t('table:table.children'),
      element: row => (row.list_descendant && row.other_people) ? (row.list_descendant.length + row.other_people.length ) : 0
    },
    {
      // name: "Phone number",
      name: t('table:table.phone_number'),
      element: row => row.phone_number
    },
    {
      // name: "Gender",
      name: t('table:table.gender'),
      element: row => row.extra_info.gender
    },
    {
      name: t('table:table.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('table:table.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
    {
      // name: "Created at",
      name: t('table:table.created_at'),
      // element: row => moment(row.created_at).format("DD-MM-YYYY, HH:mm")
      element: row => formatDateTime(row.created_at)

    },
    {
      name: t('table:table.updated_at'),
      // element: row => moment(row.updated_at).format("DD-MM-YYYY, HH:mm")
      element: row => formatDateTime(row.updated_at)
    },
    {
      // name: "Actions",
      name: t('table:table.action'),
      element: row => (
        <>
          {/* <button type="button" className="btn btn-sm btn-warning me-1"><i className="fa fa-pencil"></i> Edit</button> */}
          <Link to={`/admin/person/edit/${row._id}`} className="btn btn-sm btn-warning me-1"><i className="fa fa-pencil"></i> {t('table:button.edit')}</Link>
          <button type="button" className="btn btn-sm btn-danger me-1" onClick={() => handleDelete(row._id)}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>
          <Link to={`/admin/person/detail/${row._id}`} className="btn btn-sm btn-success me-1"><i className="fa fa-pencil"></i> {t('table:button.detail')}</Link>

        </>
      )
    }
  ]

  const requestDeleteApi = () => {
    // console.log('hung deleteed', deleteType, deleteItem, selectedRows);
    if (deleteType === 'single') {
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/person/${deleteItem}`, 'DELETE', []).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
    else {
      // console.log('hung deleteed', selectedRows);
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/person/delete-multi`, 'DELETE', { ids: selectedRows }).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        setSelectedRows([])
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
        setDeleteMulti(true);
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
  }

  const handleDelete = (id) => {
    // console.log("single delete with id => ", id)
    setShowModal(true)
    setDeleteItem(id)
    setDeleteType('single')
  }

  const handleMultiDelete = () => {
    // console.log("multi delete => ", selectedRows)
    setShowModal(true)
    setDeleteType('multi')
  }

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true))
    let query = `?limit=${itemsPerPage}&page=${currentPage}&name=${searchString}`

    requestApi(`/person/search${query}`, 'GET', []).then(response => {
      // console.log("response hungffff=> ", response.data)
      const lastPage = Math.ceil(response.data.person.count / itemsPerPage);
      setTotalItems(response.data.person.count || 0);
      setPersons(response.data.person.items);
      setNumOfPage(lastPage)
      dispatch(globalLoading.actions.CONTROL_LOADING(false))
      setDeleteMulti(false);
    }).catch(err => {
      console.log(err)
      setDeleteMulti(false);
      dispatch(globalLoading.actions.CONTROL_LOADING(false))
    })


  }, [currentPage, itemsPerPage, searchString, selectedRows, refresh, dispatch])

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {/* Person List */}
            {t('title:person_list')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>
              {/* Dashboard */}
              {t('sidebar:dashboard')}
            </Link></li>
            <li className="breadcrumb-item active">
              {/* Person list */}
              {t('person_list')}
            </li>
          </ol>

          <div className='mb-3'>
            <Link className='btn btn-sm btn-success me-2' to='/admin/person/add'><i className="fa fa-plus"></i> {t('button:add_new')}</Link>
            {selectedRows.length > 0 && <button type='button' className='btn btn-sm btn-danger' onClick={handleMultiDelete}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>}
          </div>
          <DataTable
            // name="Person List"
            name={t('table:title.person_list')}
            data={persons}
            columns={columns}
            numOfPage={numOfPage}
            currentPage={currentPage}
            onRefreshPage={setRefresh}
            onPageChange={setCurrentPage}
            onChangeItemsPerPage={setItemsPerPage}
            totalNumberItems={totalItems}
            onKeySearch={(keyword) => {
              console.log("keyword in person list comp=> ", keyword)
              setSearchString(keyword)
            }}
            onSelectedRows={rows => {
              console.log("selected rows in uselist=> ", rows)
              setSelectedRows(rows)
            }}
            deleteMulti={deleteMulti}
          />
        </div>
      </main>
      <Modal show={showModal} onHide={() => setShowModal(false)} size='sm'>
        <Modal.Header closeButton>
          {/* <Modal.Title>Confirmation</Modal.Title> */}
          <Modal.Title>{t('table:modal.confirmation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Are you sure want to delete? */}
          {t('table:modal.want_delete')}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}> {t('button:close')}</Button>
          <Button className='btn-danger' onClick={requestDeleteApi}>{t('table:button.delete')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default PersonList