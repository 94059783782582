import React, { useEffect, useState } from 'react'
import { toastError, toastSuccess } from '../../../helpers/toast';
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import globalLoading from '../../../redux/reducers/globalLoading';
import { formaListParentsData, formartListSpouseOthersData, formatPersonData, fortmatObjectPerson } from '../../../helpers/formatdata';
import requestApi from '../../../api/api';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { editorConfiguration } from '../../../config/editorConfiguration';
import Select from 'react-select'
import ModalFormEdit from './ModalFormEdit';



const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: '270px', // Set your fixed width here
  }),
  control: (provided) => ({
    ...provided,
    width: '100%', // Set the width to 100% initially
    // maxWidth: '270px', // Set the maximum width for larger screens
  }),
};

const PersonUpdate = () => {
  const { t } = useTranslation(['title', 'table', 'form', 'sidebar', 'button', 'notiSuccess', 'notiError']);
  const schema = yup.object().shape({
    parent_id: yup.object()
      .shape()
      .nullable(), // for handling null value when clearing options via clicking "x"
    // .required("Parents is required"),
    first_name: yup.string().required('First name is required.').min(2, t('notiWarning:two_more_characters')),
    last_name: yup.string().required('Last name is required.').min(2, t('notiWarning:two_more_characters')),
    chinese_name: yup.string(),
    note_name: yup.string(),
    address: yup.string(),
    birth_place: yup.string(),
    burial_place: yup.string(),
    birthday: yup.string(),
    dead_day: yup.string(),
    gender: yup.string(),
    note: yup.string(),
    // email: yup.string().required('Email is required.').matches(
    //   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    //   'Invalid email address'
    // ),
    email: yup.string().when({
      is: (exists) => !!exists,
      then: (schema) => schema.matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Invalid email address'
      ),
      otherwise: (schema) => schema,
    }),
    phone_number: yup.string()
  });
  const initialSelect = {
    value: undefined,
    label: null
  };
  const params = useParams();
  const { register, setValue, control, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [optionParents, setOptionParents] = useState([]);
  const [parents, setParents] = useState(initialSelect);
  const [noteData, setNoteData] = useState({});
  const [birthday, setBirthday] = useState(new Date());
  const [deadDay, setDeadDay] = useState(new Date());

  const [showModal, setShowModal] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const [optionSpouse, setOptionSpouse] = useState([]);
  const [optionOthers, setOptionOthers] = useState([]);
  const [selectedDataModal, setSelectedDataModal] = useState([]);
  const [selectedSpouse, setSelectedSpouse] = useState(null);
  const [selectedOthers, setSelectedOthers] = useState(null);
  const [personId, setPersonId] = useState('');
  const [reload, setReload] = useState(Date.now());
  const [listSpouseData, setListSpouseData] = useState([]);
  const [listOthersData, setListOthersData] = useState([]);

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    try {
      const getDetailPerson = async () => {
        const res = await requestApi(`/person/${params.id}`, 'GET');
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        const fields = ['parent', 'email', 'phone_number', 'first_name', 'last_name',
          'chinese_name', 'note_name', 'gender', 'address', 'birth_place', 'burial_place'];
        const data = formatPersonData(res.data.data.item);
        // console.log('response--->', res.data.data.item);
        fields.forEach((field) => setValue(field, data[field]));
        setNoteData(data.note);

        setBirthday(data.birthday ? new Date(data.birthday) : '');
        setDeadDay(data.dead_day ? new Date(data.dead_day) : '');
        // console.log('note dat',data.note[0]);
        // console.log('parent---> dat', data.parent);

        setParents(data.parent);
        const listSpouse = res.data.data.item.marriage_person ? res.data.data.item.marriage_person : [];
        setOptionSpouse(formartListSpouseOthersData(listSpouse));
        setListSpouseData(listSpouse);

        const listOthers = res.data.data.item.other_people ? res.data.data.item.other_people : [];
        setOptionOthers(formartListSpouseOthersData(listOthers));
        setListOthersData(listOthers);

        setPersonId(res.data.data.item._id);
      }
      getDetailPerson();
    } catch (error) {
      console.log('error-->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
    }

  }, [reload]);
  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    try {
      const getListPerson = async () => {
        const query = `?except_person_id=${params.id}`
        const res = await requestApi(`/person${query}`, 'GET');
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        setOptionParents(formaListParentsData(res.data.data.items));
      }
      getListPerson();
    } catch (error) {
      console.log('error-->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
    }
  }, []);

  useEffect(() => {
    register('note')
  })


  const handleSubmitFormUpdate = async (data) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    data['birthday'] = birthday;
    data['dead_day'] = deadDay;
    data['parent_id'] = parents ? parents.value : null;
    const dataInput = fortmatObjectPerson(data);
    console.log('result------>', dataInput);
    try {
      const result = await requestApi(`/person/${params.id}`, 'PATCH', dataInput);
      // console.log('result------>', result);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // toastSuccess('Person has updated successfully!', 1000);
      toastSuccess(t('notiSuccess:person_updated_success'), 1000);

      setTimeout(() => {
        navigate('/admin/persons')
      }, 100);
    } catch (error) {
      console.log('error------->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // toastError('Update person failed', 2000);
      toastError(t('notiError:update_person_failed'), 2000);
    }
  }

  const handleAddSpouseModalForm = () => {
    setShowModal(true);
    setNameModal(t('title:add_new_spouse'));
    setSelectedDataModal([]);
  }
  const handleAddOthersModalForm = () => {
    setShowModal(true);
    setNameModal(t('title:add_others'));
    setSelectedDataModal([]);
  }
  const handleEditSpouseModalForm = (selectedOption) => {
    if (selectedOption) {
      setShowModal(true);
      setNameModal(t('title:update_spouse'));
      const id = selectedOption.value;
      const data = listSpouseData.filter((item) => item._id === id);
      setSelectedDataModal(data);
      setSelectedSpouse(selectedOption.value);
    } else {
      setShowModal(false);
      setNameModal('');
      setSelectedDataModal([]);
      setSelectedSpouse(null);
    }
  }

  const handleEditOthersModalForm = (selectedOption) => {
    if (selectedOption) {
      setShowModal(true);
      setNameModal(t('title:update_others'));
      const id = selectedOption.value;
      const data = listOthersData.filter((item) => item._id === id);
      setSelectedDataModal(data);
      setSelectedOthers(selectedOption.value);
    } else {
      setShowModal(false);
      setNameModal('');
      setSelectedDataModal([]);
      setSelectedOthers(null);
    }
  }
  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {t('title:update_person')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item"><Link to='/admin/persons'>{t('title:person_list')}</Link></li>
            <li className="breadcrumb-item active">{t('title:update_person')}</li>
          </ol>
          <div className='card mb-4'>
            <div className='card-header'>
              <i className='fas fa-plus me-1'></i>
              {t('title:update')}
            </div>
            <div className='card-body'>
              <div className='row mb-3'>
                <form>
                  <div className="row">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Last name: */}
                        {t('form:title.last_name')}
                      </label>
                      <input {...register("last_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_last_name')} />
                      {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* First name: */}
                        {t('form:title.first_name')}
                      </label>
                      <input {...register("first_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_first_name')} />
                      {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name.message}</p>}
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Chinese name: */}
                        {t('form:title.other_name')}
                      </label>
                      <input {...register("chinese_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_other_name')} />
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Note name: */}
                        {t('form:title.note_name')}
                      </label>
                      <input  {...register("note_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_note_name')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>Email:</label>
                      <input  {...register("email")} type='text' className='form-control' placeholder={t('form:place_holder.enter_email')} />
                      {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>{t('form:title.phone_number')}</label>
                      <input {...register("phone_number")} type='text' className='form-control' placeholder={t('form:place_holder.enter_phone_number')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>{t('form:title.address')}</label>
                      <input {...register("address")} type='text' className='form-control' placeholder={t('form:place_holder.enter_address')} />
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Place of birth: */}
                        {t('form:title.place_birth')}
                      </label>
                      <input {...register("birth_place")} type='text' className='form-control' placeholder={t('form:place_holder.enter_place_birth')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Burial ground: */}
                        {t('form:title.burial_ground')}
                      </label>
                      <input {...register("burial_place")} type='text' className='form-control' placeholder={t('form:place_holder.enter_burial_ground')} />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {/* Date of birth: */}
                        {t('form:title.date_birth')}
                      </label>
                      {/* <input {...register("birthday")} type='text' className='form-control' placeholder='Enter birthday' /> */}
                      <Controller
                        name='birthday'
                        control={control}
                        render={({ field }) => (
                          <span style={{ display: 'flex' }} className='customDatePickerWidth'>
                            <DatePicker
                              {...field}
                              className='form-control example-custom-input'
                              selected={birthday}
                              value={birthday}
                              onChange={(date) => {
                                // console.log('Date of birth new-->', date);
                                setBirthday(date)
                              }}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              scrollableYearDropdown={false}
                              dateFormat="dd-MM-yyyy"
                              maxDate={new Date()}
                              todayButton="TODAY"
                            />
                          </span>
                        )}
                      />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.date_death')}
                      </label>
                      <Controller
                        name='dead_day'
                        control={control}
                        render={({ field }) => (
                          <div style={{ display: 'flex' }} className='customDatePickerWidth'>
                            <DatePicker
                              {...field}
                              className='form-control example-custom-input'
                              selected={deadDay}
                              value={deadDay}
                              onChange={(date) => {
                                // console.log('Dead day new-->', date);
                                setDeadDay(date)
                              }}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              scrollableYearDropdown={false}
                              dateFormat="dd-MM-yyyy"
                              maxDate={new Date()}
                              todayButton="TODAY"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.gender')}
                      </label>
                      <select  {...register("gender")} className='form-select'>
                        <option value='Other'>{t('form:select.other')}</option>
                        <option value='Male'>{t('form:select.male')}</option>
                        <option value='Female'>{t('form:select.female')}</option>
                      </select>
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.parent')}
                      </label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        className="react-dropdown"
                        classNamePrefix="dropdown"
                        options={optionParents}
                        placeholder="Select Spouse"
                        defaultValue={parents}
                        value={parents}
                        onChange={(selectedOption) => {
                          setParents(selectedOption)
                        }}
                        autoFocus={true}
                      />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.others')}
                      </label>
                      <div style={{ display: 'flex' }}>
                        <Select
                          onChange={handleEditOthersModalForm}
                          options={optionOthers}
                          // defaultValue={selectedOthers}
                          value={selectedOthers}
                          autoFocus={true}
                          placeholder={t('form:place_holder.list_others')}
                          styles={customSelectStyles}
                        />
                        <button style={{ marginLeft: '10px' }} type="button" onClick={handleAddOthersModalForm} className='btn btn-success'>{t('button:add_new')}</button>
                      </div>
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.spouse')}
                      </label>
                      <div style={{ display: 'flex' }}>
                        <Select
                          onChange={handleEditSpouseModalForm}
                          options={optionSpouse}
                          // defaultValue={selectedSpouse}
                          value={selectedSpouse}
                          autoFocus={true}
                          placeholder={t('form:place_holder.list_spouse')}
                          styles={customSelectStyles}
                        />
                        <button type="button" style={{ marginLeft: '10px' }} onClick={handleAddSpouseModalForm} className='btn btn-success'>{t('button:add_new')}</button>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-6'>
                      <label htmlFor="noteTextarea1" className='form-label'>
                        {t('form:title.note')}
                      </label>
                      <CKEditor
                        className="ck-editor_editable_inline"
                        editor={Editor}
                        config={editorConfiguration}
                        data={noteData}
                        onReady={editor => {
                          register("note")
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          // console.log({ event, editor, data });
                          // console.log('data --->', data);
                          setValue('note', data);

                        }}
                      />
                    </div>
                    <div className='col-3'>
                      <button onClick={handleSubmit(handleSubmitFormUpdate)} type='button' className='mt-4 btn btn-success'>{t('button:submit')}</button>
                      <Link to={'/admin/persons'} style={{ marginLeft: '10px' }} className='mt-4 btn btn-danger' >{t('button:cancel')}</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ModalFormEdit
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        nameModal={nameModal}
        data={selectedDataModal}
        personId={personId}
        setReload={setReload}
        setSelectedSpouse={setSelectedSpouse}
        setSelectedOthers={setSelectedOthers}
      />

    </div>
  )
}

export default PersonUpdate