import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import globalLoading from '../../../redux/reducers/globalLoading';
import requestApi from '../../../api/api';
import { toastError, toastSuccess } from '../../../helpers/toast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';



const UserAdd = () => {
  const { t } = useTranslation(['title', 'form', 'button', 'notiSuccess', 'notiError', 'notiWarning']);
  const schema = yup.object().shape({
    first_name: yup.string().required(t('notiWarning:first_name_required')),
    last_name: yup.string().required(t('notiWarning:last_name_required')),
    email: yup.string().required(t('notiWarning:email_required')).matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      t('notiWarning:invalid_email_address')
    ),
    password: yup.string().required(t('notiWarning:password_required')),

    phone_number: yup.string(),
    address: yup.string(),
    birthday: yup.string(),
    gender: yup.string(),
    // confirm_password: yup.string().required('Confirm password is required.')
    //   .oneOf([yup.ref('password'), null], 'Your passwords do no match'),
    confirm_password: yup.string().when({
      is: (exists) => !!exists,
      then: (schema) => schema.oneOf([yup.ref('password'), null], t('notiWarning:confirm_password_not_match')),
      otherwise: (schema) => schema.required(t('notiWarning:confirm_password_required')),
    }),
  });
  const { register, watch, control, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [birthday, setBirthday] = useState(new Date());


  const handleSubmitFormAdd = async (data) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    // console.log('data inourt-------->', data);
    data['birthday'] = birthday;
    try {
      const result = await requestApi('/user', 'POST', JSON.stringify(data));
      // console.log('result------>', result);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // toastSuccess('User has created successfully!!!', 1000);
      toastSuccess(t('notiSuccess:user_created_success'), 1000);
      setTimeout(() => {
        navigate('/admin/users')
      }, 100);
    } catch (error) {
      // console.log('error------->', error.response.data.message);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (error.response.data.message === 'Email-already-exists') {
        // toastError('Email already exists', 2000);
        toastError(t('notiError:email_exists'), 2000);
      } else {
        // toastError('Create new user failed', 2000);
        toastError(t('notiError:create_user_failed'), 2000);
      }
    }

  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {/* Add New User */}
            {t('title:add_new_user')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item"><Link to='/admin/users'>{t('title:user_list')}</Link></li>
            <li className="breadcrumb-item active">{t('title:add_new_user')}</li>
          </ol>
          <div className='card mb-4'>
            <div className='card-header'>
              <i className='fas fa-plus me-1'></i>
              {t('title:add')}
            </div>
            <div className='card-body'>
              <div className='row mb-3'>
                <form>
                  <div className="row">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Last name: */}
                        {t('form:title.last_name')}
                      </label>
                      <input {...register("last_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_last_name')} />
                      {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* First name: */}
                        {t('form:title.first_name')}
                      </label>
                      <input {...register("first_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_first_name')} />
                      {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name.message}</p>}
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>Email:</label>
                      <input {...register("email")} type='text' className='form-control' placeholder={t('form:place_holder.enter_email')} />
                      {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Phone number: */}
                        {t('form:title.phone_number')}
                      </label>
                      <input {...register("phone_number")} type='text' className='form-control' placeholder={t('form:place_holder.enter_phone_number')} />
                      {errors.phone_number && <p style={{ color: 'red' }}>{errors.phone_number.message}</p>}
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Password: */}
                        {t('form:title.password')}
                      </label>
                      <input {...register("password")} type='password' className='form-control' placeholder={t('form:place_holder.enter_password')} />
                      {errors.password && <p style={{ color: 'red' }}>{errors.password.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Confirm Password: */}
                        {t('form:title.confirm_password')}
                      </label>
                      <div className="form-label">
                        <input  {...register("confirm_password")}
                          className="form-control" type="password" placeholder={t('form:place_holder.enter_confirm_password')} />
                        {errors.confirm_password && <p style={{ color: 'red' }}>{errors.confirm_password.message}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-4'>
                      <label className='form-label'>
                        {/* Gender: */}
                        {t('form:title.gender')}
                      </label>
                      <select  {...register("gender")} className='form-select'>
                        <option value='Other'>{t('form:select.other')}</option>
                        <option value='Male'>{t('form:select.male')}</option>
                        <option value='Female'>{t('form:select.female')}</option>
                      </select>
                    </div>
                    <div className='col-2'>
                      <label className='form-label'>
                        {/* Date of birth: */}
                        {t('form:title.date_birth')}
                      </label>
                      {/* <input {...register("birthday")} type='text' className='form-control' placeholder='Enter birthday' /> */}
                      <Controller
                        name='birthday'
                        control={control}
                        render={({ field }) => (
                          <span style={{ display: 'flex' }}>
                            <DatePicker
                              {...field}
                              className='form-control example-custom-input'
                              selected={birthday}
                              onChange={(date) => setBirthday(date)}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              scrollableYearDropdown={false}
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              todayButton="TODAY"
                            />
                          </span>
                        )}
                      />
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Address: */}
                        {t('form:title.address')}
                      </label>
                      <input {...register("address")} type='text' className='form-control' placeholder={t('form:place_holder.enter_address')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-3'>
                      <button onClick={handleSubmit(handleSubmitFormAdd)} type='button' className='mt-4 btn btn-success'>{t('button:submit')}</button>
                      <Link to={'/admin/users'} style={{ marginLeft: '10px' }} className='mt-4 btn btn-danger' >{t('button:cancel')}</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

    </div>
  )
}

export default UserAdd