import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Select from 'react-select'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import globalLoading from '../../../redux/reducers/globalLoading';
import requestApi from '../../../api/api';
import { formaListParentsData, fortmatObjectSpouse } from '../../../helpers/formatdata';
import { toastError, toastSuccess } from '../../../helpers/toast';
import { useTranslation } from 'react-i18next';
import { editorConfiguration } from '../../../config/editorConfiguration';



const DescendantAdd = () => {
  const { t } = useTranslation(['title', 'table', 'form', 'sidebar', 'button', 'notiSuccess', 'notiError','notiWarning']);
  const schema = yup.object().shape({
    person: yup
      .object()
      .shape({
        label: yup.string().required(t('notiWarning:parents_required')),
        value: yup.string().required('Parents is required')
      })
      .nullable(), // for handling null value when clearing options via clicking "x"
    // .required("Parents is required"),
    first_name: yup.string().required(t('notiWarning:first_name_required')),
    last_name: yup.string().required(t('notiWarning:last_name_required')),
    chinese_name: yup.string(),
    note_name: yup.string(),
    address: yup.string(),
    birth_place: yup.string(),
    burial_place: yup.string(),
    birthday: yup.string(),
    dead_day: yup.string(),
    gender: yup.string(),
    relation: yup.string(),
    note: yup.string(),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPerson, setSelectedPerson] = useState('');
  const [optionParents, setOptionParents] = useState([]);
  const { register, setValue, handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
  const [birthday, setBirthday] = useState(new Date());
  const [deadDay, setDeadDay] = useState(new Date());


  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    try {
      const getListParent = async () => {
        const res = await requestApi('/person', 'GET');
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        setOptionParents(formaListParentsData(res.data.data.items));
      }
      getListParent();
    } catch (error) {
      console.log('error-->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
    }
  }, []);
  const handleChangeOptions = (selectedOption) => {
    setSelectedPerson(selectedOption.value);
    // console.log('selected option-->', selectedOption.value);
  }
  const handleSubmitFormAdd = async (data) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    data['birthday'] = birthday;
    data['dead_day'] = deadDay;
    const dataInput = fortmatObjectSpouse(data);
    try {
      const result = await requestApi('/other-people', 'POST', dataInput);
      // console.log('result------>', result);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // toastSuccess('Others have added success!!!', 1000);
      toastSuccess(t('notiSuccess:others_created_success'), 1000);
      setTimeout(() => {
        navigate('/admin/others')
      }, 100);
    } catch (error) {
      console.log('error------->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      // toastError('Add others failed', 2000);
      toastError(t('notiError:add_others_failed'), 2000);
    }

  }



  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {/* Add Others */}
            {t('title:add_others')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item"><Link to='/admin/others'>{t('title:list_others')}</Link></li>
            <li className="breadcrumb-item active">{t('title:add_others')}</li>
          </ol>
          <div className='card mb-4'>
            <div className='card-header'>
              <i className='fas fa-plus me-1'></i>
              {t('title:add')}
            </div>
            <div className='card-body'>
              <div className='row mb-3'>
                <form onSubmit={handleSubmit(handleSubmitFormAdd)}>
                  <div className="row">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Last name: */}
                        {t('form:title.last_name')}
                      </label>
                      <input {...register("last_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_last_name')} />
                      {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* First name: */}
                        {t('form:title.first_name')}
                      </label>
                      <input {...register("first_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_first_name')} />
                      {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name.message}</p>}
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Chinese name: */}
                        {t('form:title.other_name')}
                      </label>
                      <input {...register("chinese_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_other_name')} />
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Note name: */}
                        {t('form:title.note_name')}
                      </label>
                      <input  {...register("note_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_note_name')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Address: */}
                        {t('form:title.address')}
                      </label>
                      <input {...register("address")} type='text' className='form-control' placeholder={t('form:place_holder.enter_address')} />
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Place of birth: */}
                        {t('form:title.place_birth')}
                      </label>
                      <input {...register("birth_place")} type='text' className='form-control' placeholder={t('form:place_holder.enter_place_birth')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-6'>
                      <label className='form-label'>
                        {/* Burial ground: */}
                        {t('form:title.burial_ground')}
                      </label>
                      <input {...register("burial_place")} type='text' className='form-control' placeholder={t('form:place_holder.enter_burial_ground')} />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {/* Date of birth: */}
                        {t('form:title.date_birth')}
                      </label>
                      {/* <input {...register("birthday")} type='text' className='form-control' placeholder='Enter birthday' /> */}
                      <Controller
                        name='birthday'
                        control={control}
                        render={({ field }) => (
                          <span style={{ display: 'flex' }} className='customDatePickerWidth'>
                            <DatePicker
                              {...field}
                              className='form-control example-custom-input'
                              selected={birthday}
                              onChange={(date) => setBirthday(date)}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              scrollableYearDropdown={false}
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              todayButton="TODAY"
                            />
                          </span>
                        )}
                      />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.date_death')}
                      </label>
                      <Controller
                        name='dead_day'
                        control={control}
                        render={({ field }) => (
                          <div style={{ display: 'flex' }} className='customDatePickerWidth'>
                            <DatePicker
                              {...field}
                              className='form-control example-custom-input'
                              selected={deadDay}
                              onChange={(date) => setDeadDay(date)}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              scrollableYearDropdown={false}
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              todayButton="TODAY"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.parent')}
                      </label>
                      <Controller
                        name='person'
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable // enable isClearable to demonstrate extra error handling
                            isSearchable={true}
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            options={optionParents}
                            // placeholder="Select Parents"
                            placeholder={t('form:place_holder.select_parent')}
                          // onChange={handleChangeOptions}
                          // onChange={(e) =>  }
                          // defaultValue={selectedPerson}
                          // autoFocus={true}
                          />
                        )}
                      />
                      <p style={{ color: 'red' }}>{errors.person?.message || errors.person?.label.message}</p>
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.gender')}
                      </label>
                      <select  {...register("gender")} className='form-select'>
                        <option value='Other'>{t('form:select.other')}</option>
                        <option value='Male'>{t('form:select.male')}</option>
                        <option value='Female'>{t('form:select.female')}</option>
                      </select>
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.relation_person')}
                      </label>
                      <select  {...register("relation")} defaultValue={'Other'} className='form-select'>
                        <option value='Other'>{t('form:select.other')}</option>
                        <option value='Son'>{t('form:select.son')}</option>
                        <option value='Daughter'>{t('form:select.daughter')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-6'>
                      <label htmlFor="noteTextarea1" className='form-label'>
                        {t('form:title.note')}
                      </label>
                      <CKEditor
                        className="ck-editor_editable_inline"
                        editor={Editor}
                        config={editorConfiguration}
                        onReady={editor => {
                          register("note")
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          // console.log('hung day', { event, editor });
                          setValue('note', data);
                        }}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <button type='submit' className='mt-4 btn btn-success'>{t('button:submit')}</button>
                      <Link to={'/admin/others'} style={{ marginLeft: '10px' }} className='mt-4 btn btn-danger' >{t('button:cancel')}</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

    </div>
  )
}

export default DescendantAdd