import React from 'react'
import Header from '../Header/Header'
import { Outlet } from 'react-router-dom'
import Pedigree from '../../Genealogy/Pedigree'

const MainUser = () => {
  return (
    <div>
      <Header />
      {/* <div id="layoutSidenav"> */}
      {/* <Pedigree /> */}
      <Outlet />
      {/* </div> */}
    </div>
  )
}

export default MainUser