import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import globalLoading from '../../../redux/reducers/globalLoading';
import { toastError } from '../../../helpers/toast';
import { getOverview } from '../../../app/overviewSlice';
import parse from "html-react-parser";


const Home = () => {
  const dispatch = useDispatch();
  const [overviewData, setOverviewData] = useState([]);
  const [countOverview, setCountOverview] = useState(0);
  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getOverview()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('getOverview--->', result.payload.data.data.items[0]);
        setOverviewData(result.payload.data.data.items);
        setCountOverview(result.payload.data.data.count);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, [])
  return (
    <main>
      <div className='container-fluid px-5'>
        {countOverview > 0 && <div
          className="preview-announcement ck-content">
          {parse(countOverview > 0 ? overviewData[countOverview - 1].content : '')}
        </div>}

      </div>
    </main>
  )
}

export default Home