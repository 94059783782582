import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import globalLoading from '../../../redux/reducers/globalLoading';
import requestApi from '../../../api/api';
import { toastError, toastSuccess } from '../../../helpers/toast';
import Select from 'react-select'
import { formaListParentsData, formartListSpouseOthersData, fortmatObjectPerson } from '../../../helpers/formatdata';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from 'react-i18next';
import { editorConfiguration } from '../../../config/editorConfiguration';
import { editOthers } from './Slice/othersFormSlice';
import { editSpouse } from './Slice/spouseFormSlice';
import ModalFormAdd from './ModalFormAdd';

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: '270px', // Set your fixed width here
  }),
  control: (provided) => ({
    ...provided,
    width: '100%', // Set the width to 100% initially
    // maxWidth: '270px', // Set the maximum width for larger screens
  }),
};

export const PersonAdd = () => {
  const { t } = useTranslation(['title', 'table', 'form', 'sidebar', 'button', 'notiWarning', 'notiSuccess', 'notiError']);
  const schema = yup.object().shape({
    parent_id: yup.object()
      .shape()
      .nullable(), // for handling null value when clearing options via clicking "x"
    // .required("Parents is required"),
    first_name: yup.string().required(t('notiWarning:first_name_required')).min(2, t('notiWarning:two_more_characters')),
    last_name: yup.string().required(t('notiWarning:last_name_required')).min(2, t('notiWarning:two_more_characters')),
    chinese_name: yup.string(),
    note_name: yup.string(),
    address: yup.string(),
    birth_place: yup.string(),
    burial_place: yup.string(),
    birthday: yup.string(),
    dead_day: yup.string(),
    gender: yup.string(),
    note: yup.string(),
    email: yup.string().when({
      is: (exists) => !!exists,
      // then: yup.string().matches(
      //   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      //   'Invalid email address'
      // ),
      then: (schema) => schema.matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Invalid email address'
      ),
      // otherwise: yup.string()
      otherwise: (schema) => schema,
    }),
    // phone_number: yup.string().required('Phone number is required.')
    phone_number: yup.string()
  });

  const { register, setValue, control, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedParents, setSelectedParents] = useState(null);
  const [optionParents, setOptionParents] = useState([]);
  const [birthday, setBirthday] = useState(new Date());
  const [deadDay, setDeadDay] = useState(new Date());

  const [showModal, setShowModal] = useState(false);
  const [nameModal, setNameModal] = useState('');
  const { listSpouse } = useSelector(state => state.spouseForm);
  const { listOthers } = useSelector(state => state.othersForm);
  const [optionSpouse, setOptionSpouse] = useState([]);
  const [optionOthers, setOptionOthers] = useState([]);
  const [selectedDataModal, setSelectedDataModal] = useState([]);
  const [selectedSpouse, setSelectedSpouse] = useState(null);
  const [selectedOthers, setSelectedOthers] = useState(null);

  useEffect(() => {
    setOptionSpouse(formartListSpouseOthersData(listSpouse));
  }, [listSpouse])
  useEffect(() => {
    setOptionOthers(formartListSpouseOthersData(listOthers));
  }, [listOthers])

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    try {
      const getListParent = async () => {
        const res = await requestApi('/person', 'GET');
        // console.log('get list parents------->', res.data.data.items);
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        setOptionParents(formaListParentsData(res.data.data.items));
      }
      getListParent();
    } catch (error) {
      console.log('error-->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
    }
  }, []);
  const handleChangeOptions = (selectedOption) => {
    // console.log('selected option-->', selectedOption);
    setSelectedParents(selectedOption ? selectedOption.value : '');
  }

  const handleSubmitFormAdd = async (data) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    data['parent_id'] = selectedParents;
    data['birthday'] = birthday;
    data['dead_day'] = deadDay;
    const dataInput = fortmatObjectPerson(data);
    // console.log('data form add hungggggggg ===>',dataInput);
    try {
      const result = await requestApi('/person', 'POST', dataInput);
      // console.log('result------>', result.data.data._id);
      console.log('listSpouse', listSpouse);
      console.log('listOthers', listOthers);
      for (const item of listSpouse) {
        // item.person = result.data.data._id;
        const newItem = { ...item, person: result.data.data._id };
        // console.log('newItem Spouse--->', newItem);
        await requestApi('/marriage-person', 'POST', newItem)
          .then(response => {
            console.log('spouse OK');
          })
          .catch(error => {
            console.log('error', error);
          })
      }
      for (const item of listOthers) {
        // item.person = result.data.data._id;
        const newItem = { ...item, person: result.data.data._id };
        // console.log('newItem Others--->', newItem);
        await requestApi('/other-people', 'POST', newItem)
          .then(response => {
            console.log('others Ok');
          })
          .catch(error => {
            console.log('error', error);
          })
      }
      dispatch(editOthers([]));
      dispatch(editSpouse([]));
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      toastSuccess(t('notiSuccess:person_created_success'), 1000);
      setTimeout(() => {
        navigate('/admin/persons')
      }, 100);
    } catch (error) {
      console.log('error------->', error);
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (error.response && error.response.data.message === 'Email-already-exists') {
        toastError(t('notiError:email_exists'), 2000);
      } else {
        toastError(t('notiError:create_person_failed'), 2000);
      }
    }
  }
  const handleAddSpouseModalForm = () => {
    setShowModal(true);
    setNameModal(t('title:add_new_spouse'));
    setSelectedDataModal([]);
  }
  const handleAddOthersModalForm = () => {
    setShowModal(true);
    setNameModal(t('title:add_others'));
    setSelectedDataModal([]);
  }
  const handleEditSpouseModalForm = (selectedOption) => {
    if (selectedOption) {
      setShowModal(true);
      setNameModal(t('title:add_new_spouse'));
      const id = selectedOption.value;
      const data = listSpouse.filter((item) => item._id === id);
      setSelectedDataModal(data);
      setSelectedSpouse(selectedOption.value);
    } else {
      setShowModal(false);
      setNameModal('');
      setSelectedDataModal([]);
      setSelectedSpouse(null);
    }
  }

  const handleEditOthersModalForm = (selectedOption) => {
    if (selectedOption) {
      setShowModal(true);
      setNameModal(t('title:add_others'));
      const id = selectedOption.value;
      const data = listOthers.filter((item) => item._id === id);
      setSelectedDataModal(data);
      setSelectedOthers(selectedOption.value);
    } else {
      setShowModal(false);
      setNameModal('');
      setSelectedDataModal([]);
      setSelectedOthers(null);
    }
  }

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {/* Add New Person */}
            {t('title:add_new_person')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item"><Link to='/admin/persons'>{t('title:person_list')}</Link></li>
            <li className="breadcrumb-item active">{t('title:add_new_person')}</li>
          </ol>
          <div className='card mb-4'>
            <div className='card-header'>
              <i className='fas fa-plus me-1'></i>
              {t('title:add')}
            </div>
            <div className='card-body'>
              <div className='row mb-3'>
                <form>
                  <div className="row">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Last name: */}
                        {t('form:title.last_name')}
                      </label>
                      <input {...register("last_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_last_name')} />
                      {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* First name: */}
                        {t('form:title.first_name')}
                      </label>
                      <input {...register("first_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_first_name')} />
                      {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name.message}</p>}
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Other name: */}
                        {t('form:title.other_name')}
                      </label>
                      <input {...register("chinese_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_other_name')} />
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Note name: */}
                        {t('form:title.note_name')}
                      </label>
                      <input  {...register("note_name")} type='text' className='form-control' placeholder={t('form:place_holder.enter_note_name')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>Email:</label>
                      <input {...register("email")} type='text' className='form-control' placeholder={t('form:place_holder.enter_email')} />
                      {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {/* Phone number: */}
                        {t('form:title.phone_number')}
                      </label>
                      <input {...register("phone_number")} type='text' className='form-control' placeholder={t('form:place_holder.enter_phone_number')} />
                      {/* {errors.phone_number && <p style={{ color: 'red' }}>{errors.phone_number.message}</p>} */}
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col'>
                      <label className='form-label'>
                        {/* Address: */}
                        {t('form:title.address')}
                      </label>
                      <input {...register("address")} type='text' className='form-control' placeholder={t('form:place_holder.enter_address')} />
                    </div>
                    <div className='col'>
                      <label className='form-label'>
                        {t('form:title.place_birth')}
                      </label>
                      <input {...register("birth_place")} type='text' className='form-control' placeholder={t('form:place_holder.enter_place_birth')} />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-6'>
                      <label className='form-label'>
                        {t('form:title.burial_ground')}
                      </label>
                      <input {...register("burial_place")} type='text' className='form-control' placeholder={t('form:place_holder.enter_burial_ground')} />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.date_birth')}
                      </label>
                      <Controller
                        name='birthday'
                        control={control}
                        render={({ field }) => (
                          <span style={{ display: 'flex' }} className='customDatePickerWidth'>
                            <DatePicker
                              {...field}
                              className='form-control example-custom-input'
                              selected={birthday}
                              onChange={(date) => setBirthday(date)}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              scrollableYearDropdown={false}
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              todayButton="TODAY"
                            />
                          </span>
                        )}
                      />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.date_death')}
                      </label>
                      <Controller
                        name='dead_day'
                        control={control}
                        render={({ field }) => (
                          <div style={{ display: 'flex' }} className='customDatePickerWidth'>
                            <DatePicker
                              {...field}
                              className='form-control example-custom-input'
                              selected={deadDay}
                              onChange={(date) => setDeadDay(date)}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              scrollableYearDropdown={false}
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              todayButton="TODAY"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.gender')}
                      </label>
                      <select  {...register("gender")} className='form-select'>
                        <option value='Other'>{t('form:select.other')}</option>
                        <option value='Male'>{t('form:select.male')}</option>
                        <option value='Female'>{t('form:select.female')}</option>
                      </select>
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.parent')}
                      </label>
                      <Select {...register("parent_id")}
                        onChange={handleChangeOptions}
                        options={optionParents}
                        defaultValue={selectedParents}
                        autoFocus={true}
                        isClearable
                      />
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.others')}
                      </label>
                      <div style={{ display: 'flex' }}>
                        <Select
                          onChange={handleEditOthersModalForm}
                          options={optionOthers}
                          // defaultValue={selectedOthers}
                          value={selectedOthers}
                          autoFocus={true}
                          isClearable={true}
                          placeholder={t('form:place_holder.list_others')}
                          styles={customSelectStyles}
                        />
                        <button style={{ marginLeft: '10px' }} type="button" onClick={handleAddOthersModalForm} className='btn btn-success'>{t('button:add_new')}</button>
                      </div>
                    </div>
                    <div className='col-3'>
                      <label className='form-label'>
                        {t('form:title.spouse')}
                      </label>
                      <div style={{ display: 'flex' }}>
                        <Select
                          onChange={handleEditSpouseModalForm}
                          options={optionSpouse}
                          // defaultValue={selectedSpouse}
                          value={selectedSpouse}
                          autoFocus={true}
                          placeholder={t('form:place_holder.list_spouse')}
                          isClearable={true}
                          styles={customSelectStyles}
                        />
                        <button type="button" style={{ marginLeft: '10px' }} onClick={handleAddSpouseModalForm} className='btn btn-success'>{t('button:add_new')}</button>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 mt-4">
                    <div className='col-6'>
                      <label htmlFor="noteTextarea1" className='form-label'>
                        {t('form:title.note')}
                      </label>
                      <CKEditor
                        className="ck-editor_editable_inline"
                        editor={Editor}
                        config={editorConfiguration}
                        onReady={editor => {
                          register("note")
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log('hung day', { event, editor });
                          setValue('note', data);
                        }}
                      />
                    </div>
                    <div className='col-6'>
                      <button onClick={handleSubmit(handleSubmitFormAdd)} type='button' className='mt-4 btn btn-success'>{t('button:submit')}</button>
                      <Link to={'/admin/persons'} style={{ marginLeft: '10px' }} className='mt-4 btn btn-danger' >{t('button:cancel')}</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ModalFormAdd
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        nameModal={nameModal}
        data={selectedDataModal}
        listSpouse={listSpouse}
        listOthers={listOthers}
        setSelectedSpouse={setSelectedSpouse}
        setSelectedOthers={setSelectedOthers}
      />

    </div>
  )
}
