export const formatPersonData = (data) => {

  const newObject = {};
  newObject['parent'] = {
    value: data.parent._id,
    label: data.parent.full_name
  }
  newObject['email'] = data.email;
  newObject['phone_number'] = data.phone_number;
  newObject['first_name'] = data.extra_info.first_name;
  newObject['last_name'] = data.extra_info.last_name;
  newObject['chinese_name'] = data.extra_info.chinese_name;
  newObject['note_name'] = data.extra_info.note_name;
  newObject['gender'] = data.extra_info.gender;
  newObject['address'] = data.extra_info.address;
  newObject['birth_place'] = data.extra_info.birth_place;
  newObject['burial_place'] = data.extra_info.burial_place;
  newObject['birthday'] = data.extra_info.birthday;
  newObject['dead_day'] = data.extra_info.dead_day;
  newObject['note'] = data.extra_info.note;
  return newObject;

}
export const fortmatObjectPerson = (data) => {
  const newObject = {
    parent_id: data.parent_id,
    email: data.email,
    phone_number: data.phone_number,
    extra_info: {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      chinese_name: data.chinese_name,
      note_name: data.note_name,
      address: data.address,
      birth_place: data.birth_place,
      burial_place: data.burial_place,
      birthday: data.birthday,
      dead_day: data.dead_day,
      note: data.note
    }
  }
  return newObject;
}
export const formaListParentsData = (data) => {
  const formatData = data.map((item) => {
    const newObject = {};
    newObject['value'] = item._id;
    newObject['label'] = item.full_name;
    return newObject;
  })
  return formatData
}

export const formartListSpouseOthersData = (data) => {
  const formatData = data.map((item) => {
    const newObject = {};
    newObject['value'] = item._id;
    newObject['label'] = item.extra_info.last_name + ' ' + item.extra_info.first_name;
    return newObject;
  })
  return formatData;
}

export const fortmatObjectSpouse = (data) => {
  const newObject = {
    person: data.person?.value,
    relation: data.relation,
    wife_hierarchy: data.wife_hierarchy,
    extra_info: {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      chinese_name: data.chinese_name,
      note_name: data.note_name,
      address: data.address,
      birth_place: data.birth_place,
      burial_place: data.burial_place,
      birthday: data.birthday,
      dead_day: data.dead_day,
      note: data.note
    }
  }
  return newObject;
}

export const fortmatSpouseData = (data) => {
  const formatData = data.map((item) => {
    const newObject = {
      person: {
        value: item.person._id,
        label: item.person.full_name
      },
      relation: item.relation,
      wife_hierarchy: item.wife_hierarchy,
      first_name: item.extra_info.first_name,
      last_name: item.extra_info.last_name,
      gender: item.extra_info.gender,
      chinese_name: item.extra_info.chinese_name,
      note_name: item.extra_info.note_name,
      address: item.extra_info.address,
      birth_place: item.extra_info.birth_place,
      burial_place: item.extra_info.burial_place,
      birthday: item.extra_info.birthday,
      dead_day: item.extra_info.dead_day,
      note: item.extra_info.note
    }
    return newObject;
  });
  return formatData;
}

export const formatMemberDetailPersonData = (data) => {

  const newObject = {};
  newObject['_id'] = data._id;
  newObject['email'] = data.email;
  newObject['phone_number'] = data.phone_number;
  newObject['first_name'] = data.extra_info.first_name;
  newObject['last_name'] = data.extra_info.last_name;
  newObject['chinese_name'] = data.extra_info.chinese_name;
  newObject['note_name'] = data.extra_info.note_name;
  newObject['gender'] = data.extra_info.gender;
  newObject['address'] = data.extra_info.address;
  newObject['birth_place'] = data.extra_info.birth_place;
  newObject['burial_place'] = data.extra_info.burial_place;
  newObject['birthday'] = data.extra_info.birthday;
  newObject['dead_day'] = data.extra_info.dead_day;
  newObject['note'] = data.extra_info.note;
  newObject['parent'] = data.parent.full_name;
  newObject['full_name'] = data.full_name;
  newObject['ancestors'] = data.ancestors;
  newObject['list_descendant'] = data.list_descendant;
  newObject['other_people'] = data.other_people;
  newObject['marriage_person'] = data.marriage_person;
  return newObject;
}

export const convertAncestor = (data, index, ind) => {
  const lengthAncestor = data.length;
  if (lengthAncestor > 0 && index === 0) {
    return <td key={ind}>Thủy thế tổ </td>;
  }
  if (lengthAncestor > 1 && index === (lengthAncestor - 1)) {
    return <td key={ind}>Con</td>;
  }

  if (lengthAncestor > 2 && index === (lengthAncestor - 2)) {
    return <td key={ind}>Cháu ông</td>;
  }

  if (lengthAncestor > 3 && index > 0) {
    return <td key={ind}>Đời {index - 1}</td>;
  }
}

export const fortmatModalData = (data) => {
  const formatData = data.map((item) => {
    const newObject = {
      _id: item._id,
      relation: item?.relation,
      wife_hierarchy: item?.wife_hierarchy,
      first_name: item.extra_info.first_name,
      last_name: item.extra_info.last_name,
      gender: item.extra_info?.gender,
      chinese_name: item.extra_info?.chinese_name,
      note_name: item.extra_info?.note_name,
      address: item.extra_info?.address,
      birth_place: item.extra_info?.birth_place,
      burial_place: item.extra_info?.burial_place,
      birthday: item.extra_info?.birthday,
      dead_day: item.extra_info?.dead_day,
      note: item.extra_info?.note
    }
    return newObject;
  });
  return formatData;
}
