import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import globalLoading from '../../../redux/reducers/globalLoading';
import { getOnePerson, personPedigree } from '../../../app/personSlice';
import { toastError } from '../../../helpers/toast';
import { birthdayDeadday, formatDate } from '../../../helpers/common';
import { formatMemberDetailPersonData } from '../../../helpers/formatdata';
import { GENDER, RELATIONSHIP, WIFEHIERARCHY } from '../../../constants/global';
import { selectedPerson } from './Slice/genealogySlice';
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";




const MemberDetail = () => {
  const dispatch = useDispatch();
  const [personData, setPersonData] = useState({});
  const [spouseData, setSpouseData] = useState([]);
  const [ancestorData, setAncestorData] = useState([]);
  const [descendantData, setDescendantData] = useState([]);
  const [othersData, setOthersData] = useState([]);
  const [notePerson, setNotePerson] = useState('');
  const { t } = useTranslation(['user', 'button', 'table', 'form']);
  const { personInfo } = useSelector(state => state.person);
  const [checkRelation, setCheckRelation] = useState(RELATIONSHIP.OTHER);

  const convertGender = (gender) => {
    if (gender === GENDER.MALE) {
      return t('form:select.male');
    }
    if (gender === GENDER.FEMALE) {
      return t('form:select.female');
    }
    if (gender === GENDER.OTHER) {
      return t('form:select.other');
    }
  }

  const convertWifeHierarchy = (hierarchy) => {
    switch (hierarchy) {
      case WIFEHIERARCHY.MAINWIFE:
        return t('form:select.main_wife');
      case WIFEHIERARCHY.CONCUBINE:
        return t('form:select.concubine');
      case WIFEHIERARCHY.LASTWIFE:
        return t('form:select.last_wife');
      default:
        return hierarchy;
    }
  }

  const spouseWifeColumns = [
    {
      name: t('table:table.index')
    },
    {
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      name: t('form:title.wife_hierarchy'),
      element: row => row.wife_hierarchy ? convertWifeHierarchy(row.wife_hierarchy) : ''
    },
    {
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const spouseHusbandColumns = [
    {
      name: t('table:table.index')
    },
    {
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const ancestorColumns = [
    {
      // name: "STT",
      name: t('table:table.index')
    },
    {
      // name: "Đời",
      name: t('user:fields.generation')
    },
    {
      // name: "Tên",
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      // name: "Năm sinh",
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      // name: "Năm mất",
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const descendantsColumns = [
    {
      // name: "STT",
      name: t('table:table.index')
    },
    {
      // name: "Tên",
      name: t('user:fields.full_name'),
      element: row => row.extra_info.last_name + " " + row.extra_info.first_name
    },
    {
      // name: "	Giới tính",
      name: t('user:fields.gender'),
      element: row => convertGender(row.extra_info.gender)
    },
    {
      // name: "Năm sinh",
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      // name: "Năm mất",
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const othersColumns = [
    {
      // name: "STT",
      name: t('table:table.index')
    },
    {
      // name: "Tên",
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      // name: "	Giới tính",
      name: t('user:fields.gender'),
      element: row => convertGender(row.extra_info.gender)
    },
    {
      // name: "Năm sinh",
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      // name: "Năm mất",
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]


  useEffect(() => {
    if (personInfo.data) {
      const data = formatMemberDetailPersonData(personInfo.data.item);
      const fields = ['full_name', 'gender', 'birthday', 'dead_day',
        'birth_place', 'burial_place', 'address']
      // fields.forEach((field) => setValue(field, data[field]));
      setPersonData(data);
      setSpouseData(data.marriage_person);
      setAncestorData(data.ancestors);
      setDescendantData(data.list_descendant);
      setOthersData(data.other_people);
      setNotePerson(data.note);
      setCheckRelation(data.marriage_person ? data.marriage_person[0].relation : RELATIONSHIP.OTHER);
    }
  }, [personInfo]);

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(personPedigree()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      // if (result.payload.status && result.payload.status === 200) {
      //   setGenealogyData(result.payload.data.data.items);
      // }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, []);


  const renderOthersData = () => {
    return (
      othersData.map((item, index) => (
        <tr key={index}>
          {
            othersColumns.map((col, ind) => {
              if (col.element) {
                return <td key={ind}>{col.element(item)}</td>
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );
  }
  const renderSpouseData = () => {
    if (checkRelation === RELATIONSHIP.WIFE) {
      return (
        spouseData.map((item, index) => (
          <tr key={index}>
            {
              spouseWifeColumns.map((col, ind) => {
                if (col.element) {
                  if (ind === 2) {
                    return <td key={ind}>{checkField(col.element(item))}</td>
                  }
                  return <td key={ind}>{col.element(item)}</td>
                }
                return <td key={ind}>{index + 1}</td>
              })
            }
          </tr>
        ))
      );
    }
    if (checkRelation === RELATIONSHIP.HUSBAND) {
      return (
        spouseData.map((item, index) => (
          <tr key={index}>
            {
              spouseHusbandColumns.map((col, ind) => {
                if (col.element) {
                  return <td key={ind}>{col.element(item)}</td>
                }
                return <td key={ind}>{index + 1}</td>
              })
            }
          </tr>
        ))
      );
    }
    return (
      spouseData.map((item, index) => (
        <tr key={index}>
          {
            spouseWifeColumns.map((col, ind) => {
              if (col.element) {
                if (ind === 2) {
                  return <td key={ind}>{checkField(col.element(item))}</td>
                }
                return <td key={ind}>{col.element(item)}</td>
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );

  }
  const renderDescendantData = () => {
    return (
      descendantData.map((item, index) => (
        <tr key={index}>
          {
            descendantsColumns.map((col, ind) => {
              if (col.element) {
                if (ind === 1) {
                  return <td key={ind}>
                    <div onClick={() => handleGetOnePerson(item._id)} className="cursor-pointer btn-result-member text-blue">
                      {col.element(item)}
                    </div>
                  </td>
                }
                return <td key={ind}>{col.element(item)}</td>
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );
  }
  const renderSpouseHeaders = () => {
    if (checkRelation === RELATIONSHIP.WIFE) {
      return spouseWifeColumns.map((col, index) => <th key={index}>{col.name}</th>)
    }
    if (checkRelation === RELATIONSHIP.HUSBAND) {
      return spouseHusbandColumns.map((col, index) => <th key={index}>{col.name}</th>)
    }
    return spouseWifeColumns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  const renderAncestorHeaders = () => {
    return ancestorColumns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  const renderOthersHeaders = () => {
    return othersColumns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  const renderDescendantHeaders = () => {
    return descendantsColumns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  const renderAncestorData = () => {
    return (
      ancestorData.map((item, index) => (
        <tr key={index}>
          {
            ancestorColumns.map((col, ind) => {
              if (col.element) {
                if (ind === 2) {
                  return <td key={ind}>
                    <span onClick={() => handleGetOnePerson(item._id)} className="cursor-pointer btn-result-member text-blue">
                      {col.element(item)}
                    </span>
                  </td>
                }
                return <td key={ind}> {col.element(item)}</td>
              }
              if (ind === 1) {
                const result = convertAncestor(ancestorData, index, ind);
                return result;
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );
  }

  const convertAncestor = (data, index, ind) => {
    const lengthAncestor = data ? data.length : 0;
    if (lengthAncestor > 0 && index === 0) {
      return <td key={ind}>Thủy thế tổ </td>;
    }
    if (lengthAncestor > 1 && index === (lengthAncestor - 1)) {
      return <td key={ind}>Con</td>;
    }

    if (lengthAncestor > 2 && index === (lengthAncestor - 2)) {
      return <td key={ind}>Cháu ông</td>;
    }

    if (lengthAncestor > 3 && index > 0) {
      return <td key={ind}>Đời {index - 1}</td>;
    }
  }
  const handleGetOnePerson = async (id) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getOnePerson(id)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        dispatch(selectedPerson(result.payload.data.data.item));
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }

  const checkField = (field) => {
    return field ? field : '//';
  }
  const checkDate = (date) => {
    return date ? formatDate(date) : '//';
  }
  const checkRelationship = (data) => {
    const dataLength = data ? data.length : 0;
    if (dataLength) {
      const relation = data[0].relation;
      switch (relation) {
        case RELATIONSHIP.WIFE:
          return t('user:fields.wife');
        case RELATIONSHIP.HUSBAND:
          return t('user:fields.husband');
        default:
          return t('user:fields.husband_wife');
      }
    }
    return '';
  }

  return (
    <div className="col-md-12 ml-1 border rounded mt-2">
      <div className="col-md-12">
        <div id="member-detail">
          <div className="col-md-12">
            <div className="row mt-2 mb-3">
              <div className="col-md-12">
                {Object.keys(personData).length > 0 && (
                  <div>
                    <h5 className="text-center user-detail-title">
                      <span>{personData.last_name + ' ' + personData.first_name}</span>
                    </h5>
                    <h6 className="text-center">
                      <span>{personData.chinese_name}</span>
                    </h6>
                    <h6 className="text-center">
                      {/* (1678-1761) */}
                      <span>{birthdayDeadday(personData.birthday, personData.dead_day)}</span>
                    </h6>
                  </div>
                )}
                {personData.note_name !== null && (<h6 className="text-center">
                  {personData.note_name}
                </h6>)}
                {/* <h6 className="text-center">
                  <span>Thủy thế tổ :</span>
                  <i>
                    <a className="cursor-pointer btn-result-member text-blue">
                      Trương Đăng Crt
                    </a>
                  </i>
                </h6>
                <h6 className="text-center">
                  <span>Đời 0: </span>
                  <i>
                    <a className="cursor-pointer btn-result-member text-blue" data-member-id="2">
                      Trương Đăng Nhất
                    </a>
                  </i>
                </h6> */}
                {/* <h6 className="text-center">
                  <span>Cháu ông:</span>
                  <i>
                    <a className="cursor-pointer btn-result-member text-blue" data-member-id="3">
                      Trương Đăng Trưởng
                    </a>
                  </i>
                </h6> */}
                {/* <h6 className="text-center">
                  <span>Con:</span>
                  <i>
                    <a className="cursor-pointer btn-result-member text-blue" data-member-id="8">
                      Trương Đăng Hưng
                    </a>
                  </i>
                </h6> */}
              </div>
            </div>
            {Object.keys(personData).length > 0 && (<div className="row">
              <div className="col-md-8">
                <div className="row">
                  <label htmlFor="example-text-input" className=" fw-bold col-md-3 col-3 col-form-label">
                    {/* Tên */}
                    {t('user:fields.full_name')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{personData.last_name + ' ' + personData.first_name}</span>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="example-text-input" className=" fw-bold col-md-3 col-3 col-form-label">
                    {/* Tên Khác */}
                    {t('user:fields.another_name')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{checkField(personData.chinese_name)}</span>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="example-search-input" className="fw-bold col-md-3 col-3 col-form-label">
                    {/* Giới tính */}
                    {t('user:fields.gender')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{convertGender(personData.gender)}</span>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="example-email-input" className="fw-bold col-md-3 col-3 col-form-label">
                    {/* Năm sinh */}
                    {t('user:fields.date_birth')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{checkDate(personData.birthday)}</span>
                  </div>
                </div>
                <div className="row">
                  <label className="fw-bold col-md-3 col-3 col-form-label">
                    {/* Nơi sinh */}
                    {t('user:fields.place_birth')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{checkField(personData.birth_place)}</span>
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="example-url-input" className="fw-bold col-md-3 col-3 col-form-label">
                    {/* Năm mất */}
                    {t('user:fields.date_death')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{checkDate(personData.dead_day)}</span>
                  </div>
                </div>

                <div className="row">
                  <label className="fw-bold col-md-3 col-3 col-form-label">
                    {/* Mộ Phẩn */}
                    {t('user:fields.burial_ground')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{checkField(personData.burial_place)}</span>
                  </div>
                </div>
                <div className="row">
                  <label className="fw-bold col-md-3 col-3 col-form-label">
                    {/* Địa chỉ */}
                    {t('user:fields.address')}
                  </label>
                  <div className="col-md-8 col-8 mt-1">
                    <span>{checkField(personData.address)}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                </div>
              </div>
            </div>)}
          </div>
          {Object.keys(personData).length > 0 && (<hr className="mt-0 mb-4" />)}
          <div className="col-12">
            {ancestorData && ancestorData.length > 0 && (<div className=" row">
              <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                {/* Tổ tiên: */}
                {t('user:fields.ancestor')}
              </label>
              <div className="col-md-9 row mt-1" id="form_relationship">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {renderAncestorHeaders()}
                    </tr>
                  </thead>
                  <tbody>
                    {renderAncestorData()}
                  </tbody>
                </table>
              </div>
            </div>)}
            {ancestorData && ancestorData.length > 0 && (<hr className="mt-0 mb-4" />)}

            {spouseData && spouseData.length > 0 && (<div className=" row">
              <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                {checkRelationship(spouseData)}
              </label>
              <div className="col-md-9 row mt-1" id="form_relationship">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {renderSpouseHeaders()}
                    </tr>
                  </thead>
                  <tbody>
                    {renderSpouseData()}
                  </tbody>
                </table>
              </div>
            </div>)}
            {spouseData && spouseData.length > 0 && (<hr className="mt-0 mb-4" />)}

            {othersData && othersData.length > 0 && (<div className=" row">
              <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                {/* Con */}
                {t('user:fields.childre')}
              </label>
              <div className="col-md-9 row mt-1" id="form_relationship">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {renderOthersHeaders()}
                    </tr>
                  </thead>
                  <tbody>
                    {renderOthersData()}
                  </tbody>
                </table>
              </div>
            </div>)}
            {othersData && othersData.length > 0 && (<hr className="mt-0 mb-4" />)}
            {descendantData && descendantData.length > 0 && (<div className=" row">
              <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                {/* Con: */}
                {t('user:fields.childre')}
              </label>
              <div className="col-md-9 row mt-1" id="form_relationship">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {renderDescendantHeaders()}
                    </tr>
                  </thead>
                  <tbody>
                    {renderDescendantData()}
                  </tbody>
                </table>
              </div>
            </div>)}
            {descendantData && descendantData.length > 0 && (<hr className="mt-0 mb-4" />)}
            {notePerson && notePerson.length > 0 && (<div className=" row">
              <label htmlFor="example-datetime-local-input" className="fw-bold fs-5 col-md-2 col-form-label">
                {/* Ghi chú */}
                {t('user:fields.note')}
              </label>
            </div>)}
            {notePerson && notePerson.length > 0 && (<div className="row">
              <div className="col-12 col-md-12">
                <div
                  className="preview ck-content"
                // dangerouslySetInnerHTML={createMarkup(notePerson)}
                >
                  {parse(notePerson)}
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>
    </div>

  )
}

export default MemberDetail