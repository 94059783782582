import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import globalLoading from '../../../redux/reducers/globalLoading';
import { toastError, toastSuccess } from '../../../helpers/toast';
import { createAnnouncement, getAnnouncement } from '../../../app/announcementSlice';
import { editorConfiguration } from '../../../config/editorConfiguration';

const schema = yup.object().shape({
  title: yup.string(),
  content: yup.string(),
})
const AnnouncementAdd = () => {
  const { t } = useTranslation(['title', 'form', 'sidebar', 'button', 'notiSuccess', 'notiError']);
  const dispatch = useDispatch();
  const [announcementData, setAnnouncementData] = useState([]);
  const [countAnnoun, setCountAnnoun] = useState(0);
  const [refresh, setRefresh] = useState(Date.now());
  const [onChangeAnnoun, setOnChangeAnnoun] = useState('');
  const [onChangeTitle, setOnChangeTitle] = useState('');

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getAnnouncement()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('getAnnouncement--->', result.payload.data.data.items[0]);
        setAnnouncementData(result.payload.data.data.items);
        setCountAnnoun(result.payload.data.data.count);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, [refresh])
  const handleSubmitFormAdd = () => {
    const inputData = {
      id: countAnnoun > 0 ? announcementData[countAnnoun - 1]._id : '',
      title: onChangeTitle,
      content: onChangeAnnoun
    }
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(createAnnouncement(inputData)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // toastSuccess('Successfully updated genealogy name!', 1000);
        toastSuccess(t('notiSuccess:announcement_created_success'), 1000);
        setRefresh(Date.now())
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }
  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {t('title:announcement')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            {/* <li className="breadcrumb-item"><Link to='/admin/others'>{t('title:announcement')}</Link></li> */}
            <li className="breadcrumb-item active">{t('title:add_announcement')}</li>
          </ol>
          <div className='card mb-4'>
            <div className='card-header'>
              <i className='fas fa-plus me-1'></i>
              {t('title:add')}
            </div>
            <div className='card-body'>
              <div className='row mb-3'>
                {/* <form onSubmit={handleSubmit(handleSubmitFormAdd)}> */}
                <div className="row justify-content-center text-center">
                  <div className='col-1'>
                    <strong className='form-label'>
                      {t('form:title.title')}
                    </strong>
                  </div>
                  <div className='col-8'>
                    <input onChange={(e) => setOnChangeTitle(e.target.value)} defaultValue={countAnnoun > 0 ? announcementData[countAnnoun - 1].title : ''} type='text' className='form-control' placeholder={t('form:place_holder.enter_title')} />
                  </div>
                </div>
                <div className="row mt-4 justify-content-center text-center">
                  <div className='col-1'>
                    <strong htmlFor="noteTextarea1" className='form-label'>
                      {t('form:title.content')}
                    </strong>
                  </div>
                  <div className='col-11'>
                    <CKEditor
                      className="ck-editor_editable_inline"
                      editor={Editor}
                      config={editorConfiguration}
                      data={countAnnoun > 0 ? announcementData[countAnnoun - 1].content : ''}
                      onReady={editor => {
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // setAnnouncementData(data);
                        setOnChangeAnnoun(data);
                      }}
                    />
                  </div>
                </div>
                <div className='row justify-content-end'>
                  <div className='col-2' style={{ marginRight: '10px' }}>
                    <button type='submit' onClick={handleSubmitFormAdd} className='mt-4 btn btn-success btn-set-width'>{t('button:submit')}</button>
                    <Link to={'/admin/dashboard'} style={{ marginLeft: '10px' }} className='mt-4 btn btn-danger btn-set-width' >{t('button:cancel')}</Link>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div >
      </main >
    </div>

  )
}

export default AnnouncementAdd