import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const addOthers = createAsyncThunk('othersForm/addOthers', async (others, thunkAPI) => {
  if (others) {
    return others;
  }
  return thunkAPI.rejectWithValue(null);
})

export const editOthers = createAsyncThunk('othersForm/editOthers', async (others, thunkAPI) => {
  if (others) {
    return others;
  }
  return thunkAPI.rejectWithValue(null);
})
const othersFormSlice = createSlice({
  name: 'othersForm',
  initialState: {
    listOthers: [],
    isLoading: false,
    errorMessage: null,
  },
  reducers: {},
  extraReducers: {
    [addOthers.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [addOthers.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    [addOthers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listOthers = [...state.listOthers, action.payload]
      // console.log('addOthers fulfilled------>', action.payload);
    },

    [editOthers.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [editOthers.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    [editOthers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listOthers = action.payload
      // console.log('editOthers fulfilled------>', action.payload);
    },
  }
})

const { reducer: othersFormReducer } = othersFormSlice;
export default othersFormReducer;