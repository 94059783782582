import React, { useEffect, useState } from 'react'
import '../assets/css/styles.css'
import { useDispatch } from 'react-redux';
import globalLoading from '../../../redux/reducers/globalLoading';
import { personPedigree } from '../../../app/personSlice';
import { toastError } from '../../../helpers/toast';
import Tree from './Tree';

const Pedigree = () => {
  const dispatch = useDispatch();
  const [personData, setPersonData] = useState([]);

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(personPedigree()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('result person genealogy--->', result.payload.data.data.items);
        setPersonData(result.payload.data.data.items);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, []);


  return (
    <div className="root-genealogy col-md-4 border rounded overflow-scroll">
      <Tree treeData={personData} />
    </div >
  )
}


export default Pedigree