import React, { useEffect, useState } from 'react';
import '../assets/css/styles.css';
import globalLoading from '../../../redux/reducers/globalLoading';
import { useDispatch, useSelector } from 'react-redux';
import { getOnePerson } from '../../../app/personSlice';
import { toastError } from '../../../helpers/toast';
import TreeNode from './TreeNode';

const Tree = ({ treeData }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const { personInfo } = useSelector(state => state.genealogy);
  function handleItemClick(itemId) {
    setSelectedItem(itemId);
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getOnePerson(itemId))
      .then((result) => {
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        if (result.error && result.payload) {
          toastError(result.payload, 2000);
        }
      })
      .catch((error) => {
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        console.log('error-->', error);
        toastError(error, 2000);
      });
  };
  useEffect(() => {
    const isPersonInfo = Object.keys(personInfo);
    if (isPersonInfo.length > 0) {
      // console.log('personInfo TREEEEE---->', isPersonInfo);
      handleItemClick(personInfo._id);
    }
  }, [personInfo])
  return (
    <div className='root-tree'>
      <TreeNode
        items={treeData}
        selectedItem={selectedItem}
        onItemClick={handleItemClick}
      />
    </div>
  );
};



export default Tree;