import React, { useState } from 'react'
import '../assets/css/styles.css'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import globalLoading from '../../../redux/reducers/globalLoading';
import { toastError } from '../../../helpers/toast';
import { getOnePerson, searchPerson } from '../../../app/personSlice';
import { useTranslation } from 'react-i18next';
import { selectedPerson } from './Slice/genealogySlice';

const labelSearch = {
  marginLeft: '15px'
};
const MemberSearch = () => {

  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [personData, setPersonData] = useState([]);
  const { t } = useTranslation(['user', 'button']);

  const handleSearch = (data) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    const inputData = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      address: data.address
    }
    dispatch(searchPerson(inputData)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('result search person--->', result.payload.data.person.items);
        setPersonData(result.payload.data.person.items);
      }
    })
      .catch((error) => {
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        console.log("error-->", error);
        toastError(error, 2000)
      })

  }

  const handleGetOnePerson = async (id) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getOnePerson(id)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('result get one person--->', result.payload.data.data.item);
        dispatch(selectedPerson(result.payload.data.data.item));
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }

  const renderData = () => {
    return (
      personData.map((item, index) => {
        return <div key={index} onClick={() => handleGetOnePerson(item._id)} className="cursor-pointer list-group-item list-group-item-action btn-result-member">
          {item.extra_info.last_name + ' ' + item.extra_info.first_name}
        </div>
      })
    )
  }

  return (
    <div className="col-md-12 ml-1">
      <div className="row">
        <div className="col-md-6 border rounded">
          <div id="form-search" className="mt-2">
            <h5>
              {/* Tìm kiếm */}
              {t('user:title.search')}
            </h5>
            <div className="col-12">
              <div className="mb-1 row">
                <label htmlFor="inputName" style={labelSearch} className="col-md-2 col-2 p-2 col-form-label">
                  {/* Tên */}
                  {t('user:search.name')}
                </label>
                <div className="col-md-9 col-10">
                  <input {...register('name')} id='inputName' className="form-control" type="text" />
                </div>
              </div>
              <div className="mb-1 row">
                <label htmlFor="inputPhone" style={labelSearch} className="col-md-2 col-2 p-2 col-form-label">
                  {/* SĐT */}
                  {t('user:search.phone_number')}
                </label>
                <div className="col-md-9 col-10">
                  <input {...register('phone')} id='inputPhone' className="form-control" type="text" />
                </div>
              </div>

              <div className="mb-1 row">
                <label htmlFor="inputEmail" style={labelSearch} className="col-md-2 col-2 p-2 col-form-label">
                  {/* Email */}
                  {t('user:search.email')}
                </label>
                <div className="col-md-9 col-10">
                  <input {...register('email')} className="form-control" id="inputEmail" type="text" />
                </div>
              </div>
              <div className="mb-1 row">
                <label htmlFor="inputAddress" style={labelSearch} className="col-md-2 col-2 p-2 col-form-label">
                  {/* Địa chỉ */}
                  {t('user:search.address')}
                </label>
                <div className="col-md-9 col-10">
                  <input {...register('address')} id='inputAddress' className="form-control" type="text" />
                </div>
              </div>

              <div className="mb-1 row" style={{ float: 'right', marginRight: '10px' }}>
                <button type="submit" onClick={handleSubmit(handleSearch)} className="btn btn-secondary">
                  {/* Tìm kiếm */}
                  {t('button:search')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 border rounded">
          <div className="mt-2">
            <h5>
              {/* Kết quả */}
              {t('user:title.result')}
            </h5>
            <div className="result" id="result">
              <div className="list-group max-200-scroll">
                {renderData()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}





export default MemberSearch