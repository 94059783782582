import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestApi from "../api/api";

export const createAnnouncement = createAsyncThunk('announcement/createAnnouncement', async (inputData, thunkAPI) => {
  try {
    const response = await requestApi('/announcement', 'POST', inputData);
    return response;
  } catch (error) {
    console.log('error Slice-->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const getAnnouncement = createAsyncThunk('announcement/getAnnouncement', async (param, thunkAPI) => {
  try {
    const response = await requestApi('/announcement', 'GET');
    return response;
  } catch (error) {
    console.log('error-------->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
})

const announcementSlice = createSlice({
  name: 'announcement',
  initialState: {
    isLoading: false,
    errorMessage: null,
    announcement: {}
  },
  reducers: {},
  extraReducers: {
    [getAnnouncement.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      // console.log('getAnnouncement pending------>', { state, action });
    },
    [getAnnouncement.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('getAnnouncement rejected------>', { state, action });
    },
    [getAnnouncement.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.announcement = action.payload.data;
      // console.log('getAnnouncement fulfilled------>', { state, action });
    },
    [createAnnouncement.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [createAnnouncement.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('rejected----->', { state, action });
    },
    [createAnnouncement.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.announcement = action.payload.data;
      // console.log('fulfilled---->', { state, action });
    }
  }
})

const { reducer: announcementReducer } = announcementSlice;
export default announcementReducer;