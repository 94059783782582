import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestApi from "../api/api";

export const getProfile = createAsyncThunk('auth/getProfile', async (params, thunkAPI) => {
  // thunkAPI.dispatch(...)
  const userInfo = await requestApi('/auth/profile', 'GET');
  return userInfo;
})
export const loginAccount = createAsyncThunk('auth/loginAccount',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await requestApi('/auth/login', 'POST', { email, password });
      // console.log('response----->>>', response)
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      return response;
    } catch (error) {
      // console.log('error-------->', error);
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  })

export const registerAccount = createAsyncThunk('auth/registerAccount',
  async ({ first_name, last_name, email, password }, thunkAPI) => {
    try {
      const response = await requestApi('/auth/register', 'POST', { first_name, last_name, email, password });
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  })

const userToken = {};
userToken['access_token'] = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null;
userToken['refresh_token'] = localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null;
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userInfo: {}, // for getProfile
    userToken,
    isLoading: false,
    errorMessage: null,
    isSuccess: false,  // for registration
  },
  reducers: {},
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [getProfile.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    },
    [loginAccount.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      // console.log('pending------>', { state, action });
    },
    [loginAccount.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('rejected------>', { state, action });
    },
    [loginAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userToken = action.payload.data;
      // state.isSuccess = true;
      // console.log('success------>', { state, action });
    },
    [registerAccount.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [registerAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [registerAccount.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  }
})
const { reducer: authReducer } = authSlice;
export default authReducer;