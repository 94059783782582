import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { formatDate, formatDateTime } from '../../../helpers/common';
import globalLoading from '../../../redux/reducers/globalLoading';
import requestApi from '../../../api/api';
import { Button, Modal } from 'react-bootstrap';
import DataTable from '../../../components/common/DataTable';
import { useTranslation } from 'react-i18next';
import { formaListParentsData } from '../../../helpers/formatdata';

const DescendantList = () => {
  const dispatch = useDispatch();
  const [descendantData, setDescendantData] = useState([]);
  const [numOfPage, setNumOfPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0);
  const [searchString, setSearchString] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [deleteItem, setDeleteItem] = useState(null)
  const [deleteType, setDeleteType] = useState('single')
  const [deleteMulti, setDeleteMulti] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [refresh, setRefresh] = useState(Date.now());
  const { t } = useTranslation(['title', 'table', 'sidebar', 'button', 'notiWarning']);
  const [optionPerson, setOptionPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState('');

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    const getListPerson = async () => {
      try {
        const res = await requestApi('/person', 'GET');
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        setOptionPerson(formaListParentsData(res.data.data.items));
        // console.log('get list person------->', res.data.data.items);
        // console.log('formaListParentsData------->', formaListParentsData(res.data.data.items));
      } catch (error) {
        console.log('error-->', error);
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
      }
    }
    getListPerson();
  }, []);

  const columns = [
    {
      name: t('table:table.index'),
    },
    {
      // name: "Full name",
      name: t('table:table.full_name'),
      element: row => row.full_name
    },
    {
      // name: "Gender",
      name: t('table:table.gender'),
      element: row => row.extra_info.gender
    },
    {
      // name: "Relationship",
      name: t('table:table.relationship'),
      element: row => row.relation
    },
    {
      // name: "Parents",
      name: t('form:title.parent'),
      element: row => row.person.full_name
    },
    {
      // name: "Date of birth",
      name: t('table:table.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      // name: "Date of death",
      name: t('table:table.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
    {
      // name: "Created at",
      name: t('table:table.created_at'),
      element: row => formatDateTime(row.created_at)

    },
    {
      // name: "Updated at",
      name: t('table:table.updated_at'),
      element: row => formatDateTime(row.updated_at)
    },
    {
      // name: "Actions",
      name: t('table:table.action'),
      element: row => (
        <>
          <Link to={`/admin/others/edit/${row._id}`} className="btn btn-sm btn-warning me-1"><i className="fa fa-pencil"></i> {t('table:button.edit')}</Link>
          <button type="button" className="btn btn-sm btn-danger me-1" onClick={() => handleDelete(row._id)}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>
          {/* <Link to={`/admin/others/edit/${row._id}`} className="btn btn-sm btn-success me-1"><i className="fa fa-pencil"></i> Detail</Link> */}

        </>
      )
    }
  ]

  const requestDeleteApi = () => {
    if (deleteType === 'single') {
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/other-people/${deleteItem}`, 'DELETE', []).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
    else {
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/other-people/delete-multi`, 'DELETE', { ids: selectedRows }).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        setSelectedRows([])
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
        setDeleteMulti(true);
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
  }

  const handleDelete = (id) => {
    console.log("single delete with id => ", id)
    setShowModal(true)
    setDeleteItem(id)
    setDeleteType('single')
  }

  const handleMultiDelete = () => {
    console.log("multi delete => ", selectedRows)
    setShowModal(true)
    setDeleteType('multi')
  }

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true))
    let query = `?limit=${itemsPerPage}&page=${currentPage}&name=${searchString}&person=${selectedPerson}`

    const getOthersData = async () => {
      try {
        const response = await requestApi(`/other-people/search${query}`, 'GET');
        const lastPage = Math.ceil(response.data.result.count / itemsPerPage);
        setTotalItems(response.data.result.count || 0);
        setDescendantData(response.data.result.items);
        setNumOfPage(lastPage)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
        setDeleteMulti(false);
      } catch (error) {
        console.log(error)
        setDeleteMulti(false);
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      }
    }
    getOthersData();
  }, [currentPage, selectedPerson, itemsPerPage, searchString, selectedRows, refresh, dispatch])

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {/* List Of Others */}
            {t('title:list_others')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item active">{t('title:list_others')}</li>
          </ol>
          <div className="alert alert-warning d-flex align-items-center" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            <div>
              {/* People who are not in the family tree (not the same last name). */}
              {t('notiWarning:not_family_tree')}
            </div>
          </div>
          <div className='mb-3'>
            <Link className='btn btn-sm btn-success me-2' to='/admin/others/add'><i className="fa fa-plus"></i> {t('button:add_new')}</Link>
            {selectedRows.length > 0 && <button type='button' className='btn btn-sm btn-danger' onClick={handleMultiDelete}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>}
          </div>
          <DataTable
            // name="List Of Others"
            personOptionData={optionPerson}
            name={t('table:title.list_other')}
            data={descendantData}
            columns={columns}
            numOfPage={numOfPage}
            currentPage={currentPage}
            onRefreshPage={setRefresh}
            onPageChange={setCurrentPage}
            totalNumberItems={totalItems}
            onChangeItemsPerPage={setItemsPerPage}
            onKeySearch={(keyword) => {
              console.log("keyword in descendant list comp=> ", keyword)
              setSearchString(keyword)
            }}
            onSelectedRows={rows => {
              console.log("selected rows in uselist=> ", rows)
              setSelectedRows(rows)
            }}
            deleteMulti={deleteMulti}
            setSelectedPerson={setSelectedPerson}
          />
        </div>
      </main>
      <Modal show={showModal} onHide={() => setShowModal(false)} size='sm'>
        <Modal.Header closeButton>
          {/* <Modal.Title>Confirmation</Modal.Title> */}
          <Modal.Title>{t('table:modal.confirmation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Are you sure want to delete? */}
          {t('table:modal.want_delete')}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>{t('button:close')}</Button>
          <Button className='btn-danger' onClick={requestDeleteApi}>{t('table:button.delete')}</Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}

export default DescendantList