import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { editorConfiguration } from '../../../config/editorConfiguration';
import { fortmatModalData, fortmatObjectSpouse } from '../../../helpers/formatdata';
import { addSpouse, editSpouse } from './Slice/spouseFormSlice';
import { addOthers, editOthers } from './Slice/othersFormSlice';

const ModalFormAdd = (props) => {
  const { setSelectedSpouse, setSelectedOthers, nameModal, data, showModal, closeModal, listSpouse, listOthers } = props;
  const { t } = useTranslation(['title', 'table', 'form', 'sidebar', 'button', 'notiSuccess', 'notiError', 'notiWarning']);
  const schema = yup.object().shape({
    first_name: yup.string().required(t('notiWarning:first_name_required')).min(2, t('notiWarning:two_more_characters')),
    last_name: yup.string().required(t('notiWarning:last_name_required')).min(2, t('notiWarning:two_more_characters')),
    chinese_name: yup.string(),
    note_name: yup.string(),
    address: yup.string(),
    birth_place: yup.string(),
    burial_place: yup.string(),
    birthday: yup.string(),
    dead_day: yup.string(),
    gender: yup.string(),
    relation: yup.string(),
    note: yup.string(),
  });
  const dispatch = useDispatch();
  const [birthday, setBirthday] = useState(new Date());
  const [deadDay, setDeadDay] = useState(new Date());
  const [selectedRelation, setSelectedRelation] = useState('Other');
  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema) });
  const [noteData, setNoteData] = useState({});
  const [dataModal, setDataModal] = useState({});

  useEffect(() => {
    if (data && !!data.length) {
      const fields = ['first_name', 'last_name',
        'chinese_name', 'note_name', 'relation', 'gender', 'address', 'birth_place',
        'burial_place', 'wife_hierarchy'
      ];
      const dataFormat = fortmatModalData(data);
      fields.forEach((field) => setValue(field, dataFormat[0][field]));
      setNoteData(dataFormat[0].note ? dataFormat[0].note : '');
      setSelectedRelation(dataFormat[0].relation);
      setBirthday(dataFormat[0].birthday ? new Date(dataFormat[0].birthday) : '');
      setDeadDay(dataFormat[0].dead_day ? new Date(dataFormat[0].dead_day) : '');
      setDataModal(dataFormat[0]);
    }
    if (data && !data.length) {
      setNoteData('');
      setSelectedRelation('Other');
      setBirthday(new Date());
      setDeadDay(new Date());
      // console.log('check if array is an empty array');
    }
  }, [data])
  const handleAddForm = (dataSubmit) => {
    const _id = Date.now();
    dataSubmit['birthday'] = birthday;
    dataSubmit['dead_day'] = deadDay;
    const dataInput = fortmatObjectSpouse(dataSubmit);
    dataInput['_id'] = _id;

    if (nameModal === t('title:add_new_spouse')) {
      dispatch(addSpouse(dataInput));
    }
    if (nameModal === t('title:add_others')) {
      dispatch(addOthers(dataInput));
    }
    reset();
    closeModal();
  }
  const handleEditForm = (dataSubmit) => {

    dataSubmit['birthday'] = birthday;
    dataSubmit['dead_day'] = deadDay;
    const dataInput = fortmatObjectSpouse(dataSubmit);
    dataInput['_id'] = dataModal._id;

    if (nameModal === t('title:add_new_spouse')) {
      const newListSpouse = listSpouse.map((item) => item._id === dataModal._id ? dataInput : item);
      dispatch(editSpouse(newListSpouse));
    }
    if (nameModal === t('title:add_others')) {
      const newListOthers = listOthers.map((item) => item._id === dataModal._id ? dataInput : item);
      dispatch(editOthers(newListOthers));
    }
    reset();
    closeModal();
  }
  const handleSubmitForm = (dataSubmit) => {
    if (data && !data.length) {
      handleAddForm(dataSubmit);
    }
    if (data && !!data.length) {
      handleEditForm(dataSubmit);
    }
  }
  const handleCloseModal = () => {
    reset();
    closeModal();
  }
  const handleDeletedItemModal = () => {
    if (nameModal === t('title:add_new_spouse')) {
      const newListSpouse = listSpouse.filter((item) => item._id !== dataModal._id);
      dispatch(editSpouse(newListSpouse));
      setSelectedSpouse(null);
    }
    if (nameModal === t('title:add_others')) {
      const newListOthers = listOthers.filter((item) => item._id !== dataModal._id);
      dispatch(editOthers(newListOthers));
      setSelectedOthers(null);
    }
    reset();
    closeModal();
  }
  return (
    <div>
      <Modal show={showModal} dialogClassName='my-modal' onHide={handleCloseModal}>
        <Modal.Header closeButton >
          <Modal.Title>
            {nameModal}
          </Modal.Title>
        </Modal.Header>
        <div className='text-center'></div>
        <Modal.Body>
          <Container fluid className='px-4'>
            <Card className='mb-4'>
              <Card.Body>
                <Form onSubmit={handleSubmit(handleSubmitForm)}>
                  <Row>
                    <Col >
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.last_name')}
                        </Form.Label>
                        <Form.Control {...register("last_name")} type='text' placeholder={t('form:place_holder.enter_last_name')} />
                        {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name.message}</p>}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.first_name')}
                        </Form.Label>
                        <Form.Control {...register("first_name")} type='text' placeholder={t('form:place_holder.enter_first_name')} />
                        {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name.message}</p>}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.other_name')}
                        </Form.Label>
                        <Form.Control {...register("chinese_name")} type='text' placeholder={t('form:place_holder.enter_other_name')} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.note_name')}
                        </Form.Label>
                        <Form.Control {...register("note_name")} type='text' placeholder={t('form:place_holder.enter_note_name')} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.address')}
                        </Form.Label>
                        <Form.Control {...register("address")} type='text' placeholder={t('form:place_holder.enter_address')} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.place_birth')}
                        </Form.Label>
                        <Form.Control  {...register("birth_place")} type='text' placeholder={t('form:place_holder.enter_place_birth')} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.burial_ground')}
                        </Form.Label>
                        <Form.Control {...register("burial_place")} type='text' placeholder={t('form:place_holder.enter_burial_ground')} />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.date_birth')}
                        </Form.Label>
                        <Controller
                          name='birthday'
                          control={control}
                          render={({ field }) => (
                            <span style={{ display: 'flex' }} className='customDatePickerWidth'>
                              <DatePicker
                                {...field}
                                className='form-control example-custom-input'
                                selected={birthday}
                                value={birthday}
                                onChange={(date) => setBirthday(date)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                scrollableYearDropdown={false}
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                todayButton="TODAY"
                              />
                            </span>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          {t('form:title.date_death')}
                        </Form.Label>
                        <Controller
                          name='dead_day'
                          control={control}
                          render={({ field }) => (
                            <div style={{ display: 'flex' }} className='customDatePickerWidth'>
                              <DatePicker
                                {...field}
                                className='form-control example-custom-input'
                                selected={deadDay}
                                value={deadDay}
                                onChange={(date) => setDeadDay(date)}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                scrollableYearDropdown={false}
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                todayButton="TODAY"
                              />
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col md={3}>
                      <Form.Label>
                        {t('form:title.gender')}
                      </Form.Label>
                      <Form.Select {...register("gender")} className='form-select'>
                        <option value='Other'>{t('form:select.other')}</option>
                        <option value='Male'>{t('form:select.male')}</option>
                        <option value='Female'>{t('form:select.female')}</option>
                      </Form.Select>
                    </Col>
                    {nameModal === t('title:add_new_spouse') && <Col md={3}>
                      <Form.Label>
                        {t('form:title.relation_spouse')}
                      </Form.Label>
                      <Form.Select  {...register("relation")} onChange={e => setSelectedRelation(e.target.value)} defaultValue={'Other'} className='form-select'>
                        <option value='Other'> {t('form:select.other')}</option>
                        <option value='Husband'>{t('form:select.husband')}</option>
                        <option value='Wife'>{t('form:select.wife')}</option>
                      </Form.Select>
                    </Col>}
                    {nameModal === t('title:add_others') && <Col md={3}>
                      <Form.Label>
                        {t('form:title.relation_person')}
                      </Form.Label>
                      <Form.Select  {...register("relation")} onChange={e => setSelectedRelation(e.target.value)} defaultValue={'Other'} className='form-select'>
                        <option value='Other'>{t('form:select.other')}</option>
                        <option value='Son'>{t('form:select.son')}</option>
                        <option value='Daughter'>{t('form:select.daughter')}</option>
                      </Form.Select>
                    </Col>}
                    {nameModal === t('title:add_new_spouse') && <Col md={3}>
                      <Form.Label>
                        {t('form:title.wife_hierarchy')}  ({t('notiWarning:when_relationship_wife')})
                      </Form.Label>
                      {
                        selectedRelation === 'Wife' ?
                          <Form.Select  {...register("wife_hierarchy")} defaultValue={'last_wife'} className='form-select'>
                            <option value='main_wife'>{t('form:select.main_wife')}</option>
                            <option value='concubine'>{t('form:select.concubine')}</option>
                            <option value='last_wife'>{t('form:select.last_wife')}</option>
                          </Form.Select> :
                          <Form.Select disabled {...register("wife_hierarchy")} defaultValue={'last_wife'} className='form-select'>
                            <option value='main_wife'>{t('form:select.main_wife')}</option>
                            <option value='concubine'>{t('form:select.concubine')}</option>
                            <option value='last_wife'>{t('form:select.last_wife')}</option>
                          </Form.Select>
                      }
                    </Col>}
                  </Row>
                  <Row className='mt-4'>
                    <Col md={6}>
                      <Form.Label>
                        {t('form:title.note')}
                      </Form.Label>
                      <CKEditor
                        className="ck-editor_editable_inline"
                        editor={Editor}
                        config={editorConfiguration}
                        data={noteData}
                        onReady={editor => {
                          register("note")
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setValue('note', data);
                        }}
                      />
                    </Col>
                    <Col md={3} className='mt-4'>
                      {data && !!data.length &&
                        <Button type='submit' onClick={handleDeletedItemModal} className='mt-2 btn btn-danger'>
                          {t('table:button:delete')}
                        </Button>
                      }
                      <Button type='submit' variant='success' style={{ marginLeft: '10px' }} className='mt-2'>
                        {t('button:save_change')}
                      </Button>
                      <Button variant="secondary" style={{ marginLeft: '10px' }} className='mt-2' onClick={handleCloseModal}>
                        {t('button:cancel')}
                      </Button>
                    </Col>
                  </Row>
                </Form>

              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModalFormAdd