import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Tree from './Tree';
import '../../../user/assets/css/styles.css'
import globalLoading from '../../../../redux/reducers/globalLoading';
import { toastError } from '../../../../helpers/toast';
import { personPedigree } from '../../../../app/personSlice';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const GenealogyAdmin = () => {
  const { t } = useTranslation(['title']);
  const [personData, setPersonData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(personPedigree()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        setPersonData(result.payload.data.data.items);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, []);
  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {t('title:genealogy')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item active">{t('title:genealogy')}</li>
          </ol>

          <div className='card mb-4'>
            <div className='card-header'>
              <i className='fas fa-plus me-1'></i>
              {t('title:detail')}
            </div>
            <div className='card-body'>
              <div className='root-genealogy' style={{ marginLeft: '1%' }}>
                <div className='root-tree'>
                  <Tree treeData={personData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default GenealogyAdmin