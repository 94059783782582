import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from 'react-router-dom';
import './assets/css/styles.css'
import './assets/css/content-styles.css'
import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Register from './components/Register';
import PrivateRoutes from './components/PrivateRoute/PrivateRoutes';
import PublicRoutes from './components/PublicRoute/PublicRoutes';
import UserList from './features/admin/user/UserList';
import PersonList from './features/admin/person/PersonList';
import { PersonAdd } from './features/admin/person/PersonAdd';
import PersonUpdate from './features/admin/person/PersonUpdate';
import PageNotFound from './components/NotFound/PageNotFound';
import Main from './components/Main/Main';
import Layout from './components/Layout/Layout';
import SpouseList from './features/admin/spouse/SpouseList';
import SpouseAdd from './features/admin/spouse/SpouseAdd';
import SpouseUpdate from './features/admin/spouse/SpouseUpdate';
import DescendantList from './features/admin/descendant/DescendantList';
import DescendantAdd from './features/admin/descendant/DescendantAdd';
import DescendantUpdate from './features/admin/descendant/DescendantUpdate';
import UserAdd from './features/admin/user/UserAdd';
import Profile from './features/admin/profile/Profile';
import ForgotPassword from './components/ForgotPassword';
import MainUser from './features/user/components/Main/MainUser';
import MemberInfo from './features/user/Genealogy/MemberInfo';
import Home from './features/user/Home/Home';
import Genealogy from './features/user/Genealogy/member/Genealogy';
import AnnouncementAdd from './features/admin/announcement/AnnouncementAdd';
import OverviewEdit from './features/admin/overview/OverviewEdit';
import Announcement from './features/user/Home/Announcement';
import PersonDetail from './features/admin/person/PersonDetail';
import GenealogyAdmin from './features/admin/person/genealogy/GenealogyAdmin';




function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route element={<Main />}>
          <Route element={<PrivateRoutes />}>
            <Route path='/admin/dashboard' element={<Dashboard />} />
            <Route path='/admin/users' element={<UserList />} />
            <Route path='/admin/users/add' element={<UserAdd />} />
            <Route path='/admin/persons' element={<PersonList />} />
            <Route path='/admin/person/add' element={<PersonAdd />} />
            <Route path='/admin/person/edit/:id' element={<PersonUpdate />} />
            <Route path='/admin/person/detail/:id' element={<PersonDetail />} />
            <Route path='/admin/person/genealogy' element={<GenealogyAdmin />} />
            <Route path='/admin/spouse' element={<SpouseList />} />
            <Route path='/admin/spouse/add' element={<SpouseAdd />} />
            <Route path='/admin/spouse/edit/:id' element={<SpouseUpdate />} />
            <Route path='/admin/others' element={<DescendantList />} />
            <Route path='/admin/others/add' element={<DescendantAdd />} />
            <Route path='/admin/others/edit/:id' element={<DescendantUpdate />} />
            <Route path='/admin/profile' element={<Profile />} />
            <Route path='/admin/announcement/add' element={<AnnouncementAdd />} />
            <Route path='/admin/overview/edit' element={<OverviewEdit />} />
          </Route>
        </Route>
        <Route element={<PublicRoutes />}>
          <Route path='/admin/login' element={<Login />} />
          <Route path='/admin/register' element={<Register />} />
          <Route path='/admin/forgot-password' element={<ForgotPassword />} />
        </Route>
        <Route element={<MainUser />}>
          <Route path='/member/member-detail' element={<MemberInfo />} />
          <Route path='/' element={<Home />} />
          <Route path='/member-list' element={<Genealogy />} />
          <Route path='/announcements' element={<Announcement />} />
        </Route>
      </Route>
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
