import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { birthdayDeadday, formatDate } from '../../../helpers/common';
import { GENDER, RELATIONSHIP, WIFEHIERARCHY } from '../../../constants/global';
import { formatMemberDetailPersonData } from '../../../helpers/formatdata';
import globalLoading from '../../../redux/reducers/globalLoading';
import { getOnePerson } from '../../../app/personSlice';
import { toastError } from '../../../helpers/toast';
import { selectedPerson } from '../../user/Genealogy/Slice/genealogySlice';
import parse from "html-react-parser";
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const PersonDetail = () => {
  const dispatch = useDispatch();
  const [personData, setPersonData] = useState({});
  const [spouseData, setSpouseData] = useState([]);
  const [ancestorData, setAncestorData] = useState([]);
  const [descendantData, setDescendantData] = useState([]);
  const [othersData, setOthersData] = useState([]);
  const [notePerson, setNotePerson] = useState('');
  const { t } = useTranslation(['user', 'title', 'button', 'table']);
  const { personInfo } = useSelector(state => state.person);
  const params = useParams();
  const [checkRelation, setCheckRelation] = useState(RELATIONSHIP.OTHER);
  const convertGender = (gender) => {
    if (gender === GENDER.MALE) {
      return t('form:select.male');
    }
    if (gender === GENDER.FEMALE) {
      return t('form:select.female');
    }
    if (gender === GENDER.OTHER) {
      return t('form:select.other');
    }
  }

  const convertWifeHierarchy = (hierarchy) => {
    switch (hierarchy) {
      case WIFEHIERARCHY.MAINWIFE:
        return t('form:select.main_wife');
      case WIFEHIERARCHY.CONCUBINE:
        return t('form:select.concubine');
      case WIFEHIERARCHY.LASTWIFE:
        return t('form:select.last_wife');
      default:
        return hierarchy;
    }
  }
  const spouseWifeColumns = [
    {
      name: t('table:table.index')
    },
    {
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      name: t('form:title.wife_hierarchy'),
      element: row => row.wife_hierarchy ? convertWifeHierarchy(row.wife_hierarchy) : ''
    },
    {
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const spouseHusbandColumns = [
    {
      name: t('table:table.index')
    },
    {
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const ancestorColumns = [
    {
      name: t('table:table.index')
    },
    {
      name: t('user:fields.generation')
    },
    {
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const descendantsColumns = [
    {
      name: t('table:table.index')
    },
    {
      name: t('user:fields.full_name'),
      element: row => row.extra_info.last_name + " " + row.extra_info.first_name
    },
    {
      name: t('user:fields.gender'),
      element: row => convertGender(row.extra_info.gender)
    },
    {
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  const othersColumns = [
    {
      name: t('table:table.index')
    },
    {
      name: t('user:fields.full_name'),
      element: row => (row.extra_info.last_name + " " + row.extra_info.first_name)
    },
    {
      name: t('user:fields.gender'),
      element: row => convertGender(row.extra_info.gender)
    },
    {
      name: t('user:fields.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('user:fields.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
  ]

  useEffect(() => {
    if (personInfo.data) {
      const data = formatMemberDetailPersonData(personInfo.data.item);
      setPersonData(data);
      setSpouseData(data.marriage_person);
      setAncestorData(data.ancestors);
      setDescendantData(data.list_descendant);
      setOthersData(data.other_people);
      setNotePerson(data.note);
      setCheckRelation(data.marriage_person ? data.marriage_person[0].relation : RELATIONSHIP.OTHER);
    }
  }, [personInfo]);

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getOnePerson(params.id)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        dispatch(selectedPerson(result.payload.data.data.item));
        const data = formatMemberDetailPersonData(result.payload.data.data.item);
        setPersonData(data);
        setSpouseData(data.marriage_person);
        setAncestorData(data.ancestors);
        setDescendantData(data.list_descendant);
        setOthersData(data.other_people);
        setNotePerson(data.note);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, [])


  const renderOthersData = () => {
    return (
      othersData.map((item, index) => (
        <tr key={index}>
          {
            othersColumns.map((col, ind) => {
              if (col.element) {
                return <td key={ind}>{col.element(item)}</td>
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );
  }
  const renderSpouseData = () => {
    if (checkRelation === RELATIONSHIP.WIFE) {
      return (
        spouseData.map((item, index) => (
          <tr key={index}>
            {
              spouseWifeColumns.map((col, ind) => {
                if (col.element) {
                  if (ind === 2) {
                    return <td key={ind}>{checkField(col.element(item))}</td>
                  }
                  return <td key={ind}>{col.element(item)}</td>
                }
                return <td key={ind}>{index + 1}</td>
              })
            }
          </tr>
        ))
      );
    }
    if (checkRelation === RELATIONSHIP.HUSBAND) {
      return (
        spouseData.map((item, index) => (
          <tr key={index}>
            {
              spouseHusbandColumns.map((col, ind) => {
                if (col.element) {
                  return <td key={ind}>{col.element(item)}</td>
                }
                return <td key={ind}>{index + 1}</td>
              })
            }
          </tr>
        ))
      );
    }
    return (
      spouseData.map((item, index) => (
        <tr key={index}>
          {
            spouseWifeColumns.map((col, ind) => {
              if (col.element) {
                if (ind === 2) {
                  return <td key={ind}>{checkField(col.element(item))}</td>
                }
                return <td key={ind}>{col.element(item)}</td>
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );

  }
  const renderDescendantData = () => {
    return (
      descendantData.map((item, index) => (
        <tr key={index}>
          {
            descendantsColumns.map((col, ind) => {
              if (col.element) {
                if (ind === 1) {
                  return <td key={ind}>
                    <div onClick={() => handleGetOnePerson(item._id)} className="cursor-pointer btn-result-member text-blue">
                      {col.element(item)}
                    </div>
                  </td>
                }
                return <td key={ind}>{col.element(item)}</td>
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );
  }
  const renderSpouseHeaders = () => {
    if (checkRelation === RELATIONSHIP.WIFE) {
      return spouseWifeColumns.map((col, index) => <th key={index}>{col.name}</th>)
    }
    if (checkRelation === RELATIONSHIP.HUSBAND) {
      return spouseHusbandColumns.map((col, index) => <th key={index}>{col.name}</th>)
    }
    return spouseWifeColumns.map((col, index) => <th key={index}>{col.name}</th>)

  }
  const renderAncestorHeaders = () => {
    return ancestorColumns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  const renderOthersHeaders = () => {
    return othersColumns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  const renderDescendantHeaders = () => {
    return descendantsColumns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  const renderAncestorData = () => {
    return (
      ancestorData.map((item, index) => (
        <tr key={index}>
          {
            ancestorColumns.map((col, ind) => {
              if (col.element) {
                if (ind === 2) {
                  return <td key={ind}>
                    <span onClick={() => handleGetOnePerson(item._id)} className="cursor-pointer btn-result-member text-blue">
                      {col.element(item)}
                    </span>
                  </td>
                }
                return <td key={ind}> {col.element(item)}</td>
              }
              if (ind === 1) {
                const result = convertAncestor(ancestorData, index, ind);
                return result;
              }
              return <td key={ind}>{index + 1}</td>
            })
          }
        </tr>
      ))
    );
  }

  const convertAncestor = (data, index, ind) => {
    const lengthAncestor = data ? data.length : 0;
    if (lengthAncestor > 0 && index === 0) {
      return <td key={ind}>Thủy thế tổ </td>;
    }
    if (lengthAncestor > 1 && index === (lengthAncestor - 1)) {
      return <td key={ind}>Con</td>;
    }

    if (lengthAncestor > 2 && index === (lengthAncestor - 2)) {
      return <td key={ind}>Cháu ông</td>;
    }

    if (lengthAncestor > 3 && index > 0) {
      return <td key={ind}>Đời {index - 1}</td>;
    }
  }
  const handleGetOnePerson = async (id) => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getOnePerson(id)).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        dispatch(selectedPerson(result.payload.data.data.item));
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }

  const checkField = (field) => {
    return field ? field : '//';
  }
  const checkDate = (date) => {
    return date ? formatDate(date) : '//';
  }
  const checkRelationship = (data) => {
    const lengthData = data ? data.length : 0;
    if (lengthData > 0) {
      const relation = data[0].relation;
      if (relation === RELATIONSHIP.WIFE) {
        return t('user:fields.wife');
      }
      if (relation === RELATIONSHIP.HUSBAND) {
        return t('user:fields.husband');
      }
      if (relation === RELATIONSHIP.OTHER) {
        return t('user:fields.husband_wife');
      }
    }
    return '';
  }
  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {t('title:member_detail')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item"><Link to='/admin/persons'>{t('title:person_list')}</Link></li>
            <li className="breadcrumb-item active">{t('title:member_detail')}</li>
          </ol>
          <div className='card mb-4'>
            <div className='card-header'>
              <i className='fas fa-plus me-1'></i>
              {t('title:detail')}
            </div>
            <div className='card-body'>
              <div className="col-md-12 ml-1 mt-2">
                <div className="col-md-12">
                  <div id="member-detail">
                    <div className="col-md-12">
                      <div className="row mt-2 mb-3">
                        <div className="col-md-12">
                          {Object.keys(personData).length > 0 && (
                            <div>
                              <h5 className="text-center user-detail-title">
                                <span>{personData.last_name + ' ' + personData.first_name}</span>
                              </h5>
                              <h6 className="text-center">
                                <span>{personData.chinese_name}</span>
                              </h6>
                              <h6 className="text-center">
                                <span>{birthdayDeadday(personData.birthday, personData.dead_day)}</span>
                              </h6>
                            </div>
                          )}
                          {personData.note_name !== null && (<h6 className="text-center">
                            {personData.note_name}
                          </h6>)}
                        </div>
                      </div>
                      {Object.keys(personData).length > 0 && (<div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <label htmlFor="example-text-input" className=" fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.full_name')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{personData.last_name + ' ' + personData.first_name}</span>
                            </div>
                          </div>
                          <div className="row">
                            <label htmlFor="example-text-input" className=" fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.another_name')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{checkField(personData.chinese_name)}</span>
                            </div>
                          </div>
                          <div className="row">
                            <label htmlFor="example-search-input" className="fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.gender')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{convertGender(personData.gender)}</span>
                            </div>
                          </div>
                          <div className="row">
                            <label htmlFor="example-email-input" className="fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.date_birth')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{checkDate(personData.birthday)}</span>
                            </div>
                          </div>
                          <div className="row">
                            <label className="fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.place_birth')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{checkField(personData.birth_place)}</span>
                            </div>
                          </div>
                          <div className="row">
                            <label htmlFor="example-url-input" className="fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.date_death')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{checkDate(personData.dead_day)}</span>
                            </div>
                          </div>

                          <div className="row">
                            <label className="fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.burial_ground')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{checkField(personData.burial_place)}</span>
                            </div>
                          </div>
                          <div className="row">
                            <label className="fw-bold col-md-3 col-3 col-form-label">
                              {t('user:fields.address')}
                            </label>
                            <div className="col-md-8 col-8 mt-1">
                              <span>{checkField(personData.address)}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row">
                          </div>
                        </div>
                      </div>)}
                    </div>
                    {Object.keys(personData).length > 0 && (<hr className="mt-0 mb-4" />)}
                    <div className="col-12">
                      {ancestorData && ancestorData.length > 0 && (<div className=" row">
                        <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                          {t('user:fields.ancestor')}
                        </label>
                        <div className="col-md-9 row mt-1" id="form_relationship">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                {renderAncestorHeaders()}
                              </tr>
                            </thead>
                            <tbody>
                              {renderAncestorData()}
                            </tbody>
                          </table>
                        </div>
                      </div>)}
                      {ancestorData && ancestorData.length > 0 && (<hr className="mt-0 mb-4" />)}
                      {spouseData && spouseData.length > 0 && (<div className=" row">
                        <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                          {checkRelationship(spouseData)}
                        </label>
                        <div className="col-md-9 row mt-1" id="form_relationship">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                {renderSpouseHeaders()}
                              </tr>
                            </thead>
                            <tbody>
                              {renderSpouseData()}
                            </tbody>
                          </table>
                        </div>
                      </div>)}
                      {spouseData && spouseData.length > 0 && (<hr className="mt-0 mb-4" />)}

                      {othersData && othersData.length > 0 && (<div className=" row">
                        <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                          {t('user:fields.childre')}
                        </label>
                        <div className="col-md-9 row mt-1" id="form_relationship">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                {renderOthersHeaders()}
                              </tr>
                            </thead>
                            <tbody>
                              {renderOthersData()}
                            </tbody>
                          </table>
                        </div>
                      </div>)}
                      {othersData && othersData.length > 0 && (<hr className="mt-0 mb-4" />)}
                      {descendantData && descendantData.length > 0 && (<div className=" row">
                        <label htmlFor="example-number-input" className="col-md-2 fw-bold fs-5 col-form-label">
                          {t('user:fields.childre')}
                        </label>
                        <div className="col-md-9 row mt-1" id="form_relationship">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                {renderDescendantHeaders()}
                              </tr>
                            </thead>
                            <tbody>
                              {renderDescendantData()}
                            </tbody>
                          </table>
                        </div>
                      </div>)}
                      {descendantData && descendantData.length > 0 && (<hr className="mt-0 mb-4" />)}
                      {notePerson && notePerson.length > 0 && (<div className="row">
                        <label htmlFor="example-datetime-local-input" className="fw-bold fs-5 col-md-2 col-form-label">
                          {t('user:fields.note')}
                        </label>
                      </div>)}
                      {notePerson && notePerson.length > 0 && (<div className="row">
                        <div className="col-12 col-md-12">
                          <div
                            className="preview ck-content"
                          >
                            {parse(notePerson)}
                          </div>
                        </div>
                      </div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </main>
    </div>
  )
}

export default PersonDetail