import React, { useEffect, useState } from 'react'
import { createMarkup } from '../../../helpers/common'
import globalLoading from '../../../redux/reducers/globalLoading';
import { useDispatch } from 'react-redux';
import { getAnnouncement } from '../../../app/announcementSlice';
import { toastError } from '../../../helpers/toast';
import parse from "html-react-parser";


const Announcement = () => {
  const dispatch = useDispatch();
  const [announcementData, setAnnouncementData] = useState([]);
  const [countAnnoun, setCountAnnoun] = useState(0);
  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    dispatch(getAnnouncement()).then((result) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      if (result.error && result.payload) {
        toastError(result.payload, 2000)
      }
      if (result.payload.status && result.payload.status === 200) {
        // console.log('getAnnouncement--->', result.payload.data.data.items[0]);
        setAnnouncementData(result.payload.data.data.items);
        setCountAnnoun(result.payload.data.data.count);
      }
    }).catch((error) => {
      dispatch(globalLoading.actions.CONTROL_LOADING(false));
      console.log("error-->", error);
      toastError(error, 2000)
    })
  }, [])
  return (
    <main>
      <div className='container mt-5'>
        <div className="row">
          <div id="home">
            {/* <div
              className="preview-announcement"
              dangerouslySetInnerHTML={createMarkup(countAnnoun > 0 ? announcementData[countAnnoun - 1].content : '')}>
            </div> */}
            <div
              className="preview-announcement ck-content">
              {parse(countAnnoun > 0 ? announcementData[countAnnoun - 1].content : '')}
            </div>
          </div>
        </div>
      </div>
    </main >
  )
}

export default Announcement