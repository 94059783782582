import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const LiveSearch = ({ onKeySearch }) => {
  const [keyword, setKeyword] = useState('')
  const { t } = useTranslation(['table']);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      console.log("call func onKeySearch")
      onKeySearch(keyword)
    }, 500)

    return () => clearTimeout(delayDebounce)
  }, [keyword])

  const onTyping = (event) => {
    const target = event.target;

    console.log("keyword typing=> ", target.value)

    setKeyword(target.value)
  }
  return (
    <div className='input-group'>
      <input type="search" onChange={onTyping} value={keyword} className="form-control form-control-sm border-end-0" placeholder={t('table:table.name_email')} />
      <span class="input-group-text bg-white">
        <i class="fa fa-search"></i>
      </span>
    </div>

  )
}

export default LiveSearch