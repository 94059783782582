import headerAdmin from "./headerAdmin.json"
import sidebar from './sidebar.json'
import dashboard from './dashboard.json'
import table from './table.json'
import button from './button.json'
import title from './title.json'
import form from './form.json'
import profile from './profile.json'
import notiError from './notiError.json'
import notiSuccess from './notiSuccess.json'
import user from './user.json'
import notiWarning from './notiWarning.json'
const vi = {
  headerAdmin,
  sidebar,
  dashboard,
  table,
  button,
  title,
  profile,
  form,
  notiError,
  notiSuccess,
  user,
  notiWarning
}
export default vi;