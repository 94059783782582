import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestApi from "../api/api";

export const getGenealogyName = createAsyncThunk('dashboard/getGenealogyName', async (param, thunkAPI) => {
  try {
    const response = await requestApi('/genealogy-name', 'GET');
    return response;
  } catch (error) {
    console.log('error-------->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
})

export const updateGenealogyName = createAsyncThunk('dashboard/updateGenealogyName', async (inputData, thunkAPI) => {
  try {
    const response = await requestApi('/genealogy-name', 'POST', inputData);
    return response;
  } catch (error) {
    console.log('error-------->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    genealogyName: {},
    isLoading: false,
    errorMessage: null,
  },
  reducers: {},
  extraReducers: {
    [getGenealogyName.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      // console.log('getGenealogyName pending------>', { state, action });
    },
    [getGenealogyName.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('getGenealogyName rejected------>', { state, action });
    },
    [getGenealogyName.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listPersons = action.payload.data;
      // console.log('getGenealogyName fulfilled------>', { state, action });
    },

    [updateGenealogyName.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
      // console.log('updateGenealogyName pending------>', { state, action });
    },
    [updateGenealogyName.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('updateGenealogyName rejected------>', { state, action });
    },
    [updateGenealogyName.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.listPersons = action.payload.data;
      // console.log('updateGenealogyName fulfilled------>', { state, action });
    }
  }
})

const { reducer: dashboardReducer } = dashboardSlice;
export default dashboardReducer;