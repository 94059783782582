import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const selectedPerson = createAsyncThunk('genealogy/selectedPerson', async (person, thunkAPI) => {
  if (person) {
    return person;
  }
  return thunkAPI.rejectWithValue(null);
})

const genealogySlice = createSlice({
  name: 'genealogy',
  initialState: {
    personInfo: {},
    isLoading: false,
    errorMessage: null,
  },
  reducers: {},
  extraReducers: {
    [selectedPerson.pending]: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
      // console.log('selectedPerson pending------>', { state });
    },
    [selectedPerson.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('selectedPerson rejected------>', { state, action });
    },
    [selectedPerson.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.personInfo = action.payload;
      // console.log('selectedPerson fulfilled------>', action.payload);
    },
  }

})

const { reducer: genealogyReducer } = genealogySlice;
export default genealogyReducer;