
export const PHOTO_CATEGORY_OPTIONS = [
  { value: 1, label: 'Technology' },
  { value: 2, label: 'Education' },
  { value: 3, label: 'Nature' },
  { value: 4, label: 'Animals' },
  { value: 5, label: 'Styles' },
];

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
}

export const WIFEHIERARCHY = {
  MAINWIFE: 'main_wife',
  CONCUBINE: 'concubine',
  LASTWIFE: 'last_wife',
  EMPTY: '',
}

export const RELATIONSHIP = {
  CHILD: 'Child',
  GRANDCHILDREN: 'Grandchildren',
  OTHER: 'Other',
  HUSBAND: 'Husband',
  WIFE: 'Wife',
  SON: 'Son',
  DAUGHTER: 'Daughter',
}




