import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestApi from "../api/api";

export const editOverview = createAsyncThunk('overview/editOverview', async (inputData, thunkAPI) => {
  try {
    const response = await requestApi('/overview', 'POST', inputData);
    return response;
  } catch (error) {
    console.log('error Slice-->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
})

export const getOverview = createAsyncThunk('overview/getOverview', async (param, thunkAPI) => {
  try {
    const response = await requestApi('/overview', 'GET');
    return response;
  } catch (error) {
    console.log('error-------->', error);
    if (error.response && error.response.data.message) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
})
const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    isLoading: false,
    errorMessage: null,
    overview: {}
  },
  reducers: {},
  extraReducers: {
    [editOverview.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [editOverview.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('rejected----->', { state, action });
    },
    [editOverview.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.overview = action.payload.data;
      // console.log('fulfilled---->', { state, action });
    },
    [getOverview.pending]: (state, action) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    [getOverview.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      // console.log('rejected----->', { state, action });
    },
    [getOverview.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.overview = action.payload.data;
      // console.log('fulfilled---->', { state, action });
    }
  }
})

const { reducer: overviewReducer } = overviewSlice;
export default overviewReducer;