import moment from "moment";
import DOMPurify from 'dompurify';
import { GENDER } from "../constants/global";

export const formatDateTime = (data) => {
  return moment(data).format("DD-MM-YYYY, HH:mm")
}

export const formatDate = (data) => {
  return moment(data).format("DD-MM-YYYY")
}

export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html)
  }
}

export const birthdayDeadday = (birth, dead) => {
  let formatYear = '';
  if (!birth && dead) {
    return formatYear = "?-" + moment(dead).format("YYYY");
  }
  if (birth && !dead) {
    return formatYear = moment(birth).format("YYYY") + "-?";
  }
  if (!birth && !dead) {
    return formatYear = "?-?";
  }
  formatYear = moment(birth).format("YYYY") + "-" + moment(dead).format("YYYY");
  return formatYear;
}


