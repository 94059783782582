import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../i18n/locales/en/index';
import vi from '../i18n/locales/vi/index';
// import LanguageDetector from 'i18next-browser-languagedetector';

export const languages = {
  vi: {
    flag: require('../assets/images/flags/vietnam.jpg'),
    language: "vi",
    title: "Vi",
  },
  en: {
    flag: require('../assets/images/flags/us.jpg'),
    language: "en",
    title: "En",
  },
}
const resources = {
  en,
  vi
};
const defaultNS = 'dashboard'
i18n
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'vi',
    ns: ['dashboard'],
    fallbackLng: localStorage.getItem('i18nextLng') || 'vi',
    // fallbackLng: 'vi',
    // keySeparator: false,
    defaultNS,
    // detection: {
    //   order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
    //   caches: ['cookie', 'localStorage']
    // },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;