import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import globalLoading from "../redux/reducers/globalLoading";
import authReducer from './authSlice';
import profileReducer from './profileSlice';
import personReducer from './personSlice';
import genealogyReducer from '../features/user/Genealogy/Slice/genealogySlice';
import dashboardReducer from './dashboardSlice';
import announcementReducer from './announcementSlice';
import overviewReducer from './overviewSlice';
import spouseFormReducer from '../features/admin/person/Slice/spouseFormSlice';
import othersFormReducer from '../features/admin/person/Slice/othersFormSlice';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    globalLoading: globalLoading.reducer,
    auth: authReducer,
    profile: profileReducer,
    person: personReducer,
    genealogy: genealogyReducer,
    dashboard: dashboardReducer,
    announcement: announcementReducer,
    overview: overviewReducer,
    spouseForm: spouseFormReducer,
    othersForm: othersFormReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
