import React, { useEffect, useState } from 'react'
import LiveSearch from './LiveSearch';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'

const customSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: '270px',
    marginLeft: 'auto',
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
  }),
};
const DataTable = (props) => {
  const {
    name, data, columns, currentPage, numOfPage,
    totalNumberItems, onPageChange, onChangeItemsPerPage, onKeySearch,
    onSelectedRows, deleteMulti,
    personOptionData, setSelectedPerson
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [limitItems, setLimitItems] = useState(10);
  const { t } = useTranslation(['table', 'form']);
  const offset = ((currentPage - 1) * limitItems) + 1;
  // console.log('typespff--->', currentPage,numOfPage)
  const toPage = currentPage === numOfPage ? totalNumberItems : (offset + limitItems - 1);
  const renderHeaders = () => {
    return columns.map((col, index) => <th key={index}>{col.name}</th>)
  }
  useEffect(() => {
    if (deleteMulti) {
      setSelectedRows([]);
    }
    onSelectedRows(selectedRows)
  }, [onSelectedRows, selectedRows])

  const onchangeSelectPerson = (selectedOption) => {
    if (selectedOption) {
      setSelectedPerson(selectedOption.value);
    } else {
      setSelectedPerson('');
    }
  }

  const renderData = () => {
    let paginate = (currentPage - 1) * limitItems;
    return (
      data.map((item, index) => (
        <tr key={index}>
          <td><input type="checkbox" checked={selectedRows.includes(String(item._id)) ? true : false} className="form-check-input" value={item._id} onChange={onClickCheckbox} /></td>
          {
            columns.map((col, ind) => {
              if (col.element) {
                if (col.name === t('table:table.gender') || col.name === t('table:table.relationship')) {
                  return <td key={ind}>{t(`form:select.${col.element(item).toLowerCase()}`)}</td>
                }
                if (col.name === t('table:table.wife_hierarchy')) {
                  return <td key={ind}> {item.wife_hierarchy ? t(`form:select.${col.element(item)}`) : ""} </td>
                }
                return <td key={ind}>{col.element(item)}</td>
              }
              return <td key={ind}>{paginate + index + 1}</td>
            })
          }
        </tr>
      ))
    )
  }

  const onClickCheckbox = (event) => {
    let checked = event.target.checked;
    let value = event.target.value;
    if (checked) {
      if (!selectedRows.includes(value)) {
        setSelectedRows([...selectedRows, value])
      }
    } else {
      let index = selectedRows.indexOf(value)
      const temp = [...selectedRows];
      temp.splice(index, 1)
      setSelectedRows(temp)
    }
  }

  const onSelectAll = (event) => {
    if (event.target.checked) {
      const temp = data.map(element => String(element._id))
      setSelectedRows(temp)
    } else {
      setSelectedRows([])
    }
  }

  const renderPagination = () => {
    const pagination = [];
    const nextPage = currentPage + 1 > numOfPage ? null : currentPage + 1;
    const prevPage = currentPage - 1 < 1 ? null : currentPage - 1;

    pagination.push(
      <li key="prev" className={prevPage ? "page-item" : "page-item disabled"}>
        <button className='page-link' onClick={() => onPageChange(prevPage)}>&laquo;</button>
      </li>
    )

    for (let i = 1; i <= numOfPage; i++) {
      pagination.push(
        <li key={i} className={currentPage === i ? "page-item active" : "page-item"}>
          <button className='page-link' onClick={() => onPageChange(i)}>{i}</button>
        </li>
      )
    }

    pagination.push(
      <li key="next" className={nextPage ? "page-item" : "page-item disabled"}>
        <button className='page-link' onClick={() => onPageChange(nextPage)}>&raquo;</button>
      </li>
    )

    return pagination;
  }

  const onChangeOption = (event) => {
    const target = event.target;
    // console.log("change item per page to=> ", target.value)
    onChangeItemsPerPage(target.value);
    onPageChange(1);
    setLimitItems(target.value);
  }
  const handlePageClick = (event) => {
    // console.log("change item per page click to=> ", +event.selected + 1)
    onPageChange(+event.selected + 1);
  };



  return (
    <div className="card mb-4">
      <div className="card-header">
        <i className="fas fa-table me-1"></i>
        {name}
      </div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <div className='d-inline-flex'>
              <div className='p-1'>{t('table:table.showing')}</div>
              <div className='p-1'>
                <select name="example_length" defaultValue={10} className="form-select form-select-sm" onChange={onChangeOption}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className='p-1'>{t('table:table.entries')}</div>
            </div>
          </div>
          {name !== t('table:title.person_list') && <div className='col-12 col-md-6 col-lg-4'>
            <Select
              onChange={onchangeSelectPerson}
              options={personOptionData}
              // defaultValue={selectedPerson}
              // value={selectedPerson}
              // autoFocus={true}
              isClearable={true}
              placeholder={t('table:title.person_list')}
              styles={customSelectStyles}
            />
          </div>
          }
          {
            name !== t('table:title.person_list') && <div className="col-12 col-md-6 col-lg-2 d-flex justify-content-md-end">
              <div className='d-inline-flex float-end'>
                <LiveSearch onKeySearch={onKeySearch} />
              </div>
            </div>
          }
          {
            name === t('table:title.person_list') && <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-md-end">
              <div className='d-inline-flex'>
                <LiveSearch onKeySearch={onKeySearch} />
              </div>
            </div>
          }
        </div>
        <table className="table table-striped table-bordered" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <td><input checked={selectedRows.length === data.length && data.length > 0 ? true : false} type="checkbox" className="form-check-input" onChange={onSelectAll} /></td>
              {renderHeaders()}
            </tr>
          </thead>
          <tbody>
            {renderData()}
          </tbody>
        </table>

        {numOfPage > 0 && (
          <div className="row">
            <div className='col-md-6'>
              <span>
                {t('table:table.showing')} {offset} {t('table:table.to')} { }
                {toPage} {t('table:table.of')} { }
                {totalNumberItems} {t('table:table.entries')}
              </span>
            </div>
            <div className="col-sm-12 col-6 col-md-6">
              <div className="pagination justify-content-end">
                <ReactPaginate
                  // nextLabel="next >"
                  nextLabel={t('table:table.next') + ' >'}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={4}
                  marginPagesDisplayed={2}
                  pageCount={numOfPage}
                  // previousLabel="< previous"
                  previousLabel={'< ' + t('table:table.previous')}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                // showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total}`}
                // total={data.length}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DataTable