import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
  const location = useLocation();
  const { t } = useTranslation(['sidebar']);
  const [selectedItem, setSelectedItem] = useState('');
  const handleItemClick = (itemPath) => {
    setSelectedItem(itemPath);
  };
  return (
    <div id="layoutSidenav_nav">
      <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <div className="sb-sidenav-menu-heading"></div>
            <div className="nav-link" href="/#">
              <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
              <Link to='/admin/dashboard' className={`nav-link ${selectedItem === 'dashboard' ? 'is-active' : ''}`} onClick={() => handleItemClick('dashboard')}>
                {/* Dashboard */}
                {t('sidebar:dashboard')}
              </Link>
            </div>
            {/* <div className="sb-sidenav-menu-heading">Interface</div> */}

            <a className={`nav-link collapsed ${selectedItem === 'overview' ? 'is-active' : ''}`} href="/#" data-bs-toggle="collapse" data-bs-target="#collapseOverview" aria-expanded="false" aria-controls="collapseLayouts" onClick={() => handleItemClick('overview')}>
              <div className="sb-nav-link-icon"><i className="fa fa-home"></i></div>
              {t('sidebar:overview')}
              <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
            </a>
            <div className={`collapse ${selectedItem === 'overview' ? 'show' : ''}`} id="collapseOverview" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link to='/admin/overview/edit' className={`nav-link ${location.pathname === '/admin/overview/edit' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:edit_overview')}
                </Link>
              </nav>
            </div>

            <a className={`nav-link collapsed ${selectedItem === 'person' ? 'is-active' : ''}`} href="/#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts" onClick={() => handleItemClick('person')}>
              <div className="sb-nav-link-icon"><i className="fa fa-users"></i></div>
              {/* Person */}
              {t('sidebar:person')}
              <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
            </a>
            <div className={`collapse ${selectedItem === 'person' ? 'show' : ''}`} id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link to='/admin/person/add' className={`nav-link ${location.pathname === '/admin/person/add' ? 'is-active' : ''}`}>
                  {/* Add Person */}
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:add_person')}
                </Link>
                <Link to='/admin/persons' className={`nav-link ${location.pathname === '/admin/persons' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:person_list')}
                </Link>
                <Link to='/admin/person/genealogy' className={`nav-link ${location.pathname === '/admin/person/genealogy' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:genealogy')}
                </Link>
              </nav>
            </div>

            <a className={`nav-link collapsed ${selectedItem === 'spouse' ? 'is-active' : ''}`} href="/#" data-bs-toggle="collapse" data-bs-target="#collapseSpouse" aria-expanded="false" aria-controls="collapseLayouts" onClick={() => handleItemClick('spouse')}>
              <div className="sb-nav-link-icon"><i className="fas fa-people-arrows"></i></div>
              {/* Spouse */}
              {t('sidebar:spouse')}
              <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
            </a>
            <div className={`collapse ${selectedItem === 'spouse' ? 'show' : ''}`} id="collapseSpouse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link to='/admin/spouse/add' className={`nav-link ${location.pathname === '/admin/spouse/add' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {/* Add Spouse */}
                  {t('sidebar:add_spouse')}
                </Link>
                <Link to='/admin/spouse' className={`nav-link ${location.pathname === '/admin/spouse' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:spouse_list')}
                </Link>
              </nav>
            </div>

            <a className={`nav-link collapsed ${selectedItem === 'others' ? 'is-active' : ''}`} href="/#" data-bs-toggle="collapse" data-bs-target="#collapseDescendant" aria-expanded="false" aria-controls="collapseLayouts" onClick={() => handleItemClick('others')}>
              <div className="sb-nav-link-icon"><i className="fas fa-user-friends"></i></div>
              {/* List Of Others */}
              {t('sidebar:list_others')}
              <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
            </a>
            <div className={`collapse ${selectedItem === 'others' ? 'show' : ''}`} id="collapseDescendant" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link to='/admin/others/add' className={`nav-link ${location.pathname === '/admin/others/add' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:add_others')}
                </Link>
                <Link to='/admin/others' className={`nav-link ${location.pathname === '/admin/others' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:list_others')}
                </Link>
              </nav>
            </div>

            <a className={`nav-link collapsed ${selectedItem === 'users' ? 'is-active' : ''}`} href="/#" data-bs-toggle="collapse" data-bs-target="#collapseUser" aria-expanded="false" aria-controls="collapseLayouts" onClick={() => handleItemClick('users')}>
              <div className="sb-nav-link-icon"><i className="fas fa-user-circle"></i></div>
              {/* User */}
              {t('sidebar:user')}
              <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
            </a>
            <div className={`collapse ${selectedItem === 'users' ? 'show' : ''}`} id="collapseUser" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link to='/admin/users/add' className={`nav-link ${location.pathname === '/admin/users/add' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:add_user')}
                </Link>
                <Link to='/admin/users' className={`nav-link ${location.pathname === '/admin/users' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:list_user')}
                </Link>
              </nav>
            </div>

            <a className={`nav-link collapsed ${selectedItem === 'announcement' ? 'is-active' : ''}`} href="/#" data-bs-toggle="collapse" data-bs-target="#collapseAnnouncement" aria-expanded="false" aria-controls="collapseLayouts" onClick={() => handleItemClick('announcement')}>
              <div className="sb-nav-link-icon"><i className="far fa-bell"></i></div>
              {/* Announcement */}
              {t('sidebar:announcement')}
              <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
            </a>
            <div className={`collapse ${selectedItem === 'announcement' ? 'show' : ''}`} id="collapseAnnouncement" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link to='/admin/announcement/add' className={`nav-link ${location.pathname === '/admin/announcement/add' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:add_announcement')}
                </Link>
                {/* <Link to='/admin/others' className={`nav-link ${location.pathname === '/admin/others' ? 'is-active' : ''}`}>
                  <div className="sb-nav-link-icon"> <i className="fas fa-long-arrow-alt-right"></i></div>
                  {t('sidebar:list_others')}
                </Link> */}
              </nav>
            </div>

          </div>
        </div>
      </nav>
    </div>
  )
}

export default Sidebar