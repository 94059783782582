import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { formatDate, formatDateTime } from '../../../helpers/common';
import { Link } from 'react-router-dom';
import globalLoading from '../../../redux/reducers/globalLoading';
import requestApi from '../../../api/api';
import DataTable from '../../../components/common/DataTable';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formaListParentsData } from '../../../helpers/formatdata';

const SpouseList = () => {
  const dispatch = useDispatch();
  const [spouseData, setSpouseData] = useState([]);
  const [numOfPage, setNumOfPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [searchString, setSearchString] = useState('')
  const [totalItems, setTotalItems] = useState(0);
  const [selectedRows, setSelectedRows] = useState([])

  const [deleteItem, setDeleteItem] = useState(null)
  const [deleteType, setDeleteType] = useState('single')

  const [showModal, setShowModal] = useState(false)
  const [refresh, setRefresh] = useState(Date.now());
  const [deleteMulti, setDeleteMulti] = useState(false);
  const { t } = useTranslation(['title', 'table', 'sidebar', 'button']);
  const [optionPerson, setOptionPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState('');

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true));
    const getListPerson = async () => {
      try {
        const res = await requestApi('/person', 'GET');
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
        setOptionPerson(formaListParentsData(res.data.data.items));
        // console.log('get list person------->', res.data.data.items);
        // console.log('formaListParentsData------->', formaListParentsData(res.data.data.items));
      } catch (error) {
        console.log('error-->', error);
        dispatch(globalLoading.actions.CONTROL_LOADING(false));
      }
    }
    getListPerson();
  }, []);

  const columns = [
    {
      name: t('table:table.index'),
    },
    {
      name: t('table:table.full_name'),
      element: row => row.extra_info.last_name + ' ' + row.extra_info.first_name
    },
    {
      name: t('table:table.gender'),
      element: row => row.extra_info.gender
    },
    {
      name: t('table:table.relationship'),
      element: row => row.relation
    },
    {
      name: t('table:table.wife_hierarchy'),
      element: row => row?.wife_hierarchy
    },
    {
      name: t('table:table.spouse'),
      element: row => row.person.full_name
    },
    {
      name: t('table:table.date_birth'),
      element: row => row.extra_info.birthday ? formatDate(row.extra_info.birthday) : ''
    },
    {
      name: t('table:table.date_death'),
      element: row => row.extra_info.dead_day ? formatDate(row.extra_info.dead_day) : ''
    },
    {
      name: t('table:table.created_at'),
      element: row => formatDateTime(row.created_at)
    },
    {
      name: t('table:table.updated_at'),
      element: row => formatDateTime(row.updated_at)
    },
    {
      name: t('table:table.action'),
      element: row => (
        <>
          <Link to={`/admin/spouse/edit/${row._id}`} className="btn btn-sm btn-warning me-1"><i className="fa fa-pencil"></i> {t('table:button.edit')}</Link>
          <button type="button" className="btn btn-sm btn-danger me-1" onClick={() => handleDelete(row._id)}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>
          {/* <Link to={`/admin/spouse/edit/${row._id}`} className="btn btn-sm btn-success me-1"><i className="fa fa-pencil"></i> Detail</Link> */}

        </>
      )
    }
  ]

  const requestDeleteApi = () => {
    if (deleteType === 'single') {
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/marriage-person/${deleteItem}`, 'DELETE', []).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
    else {
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/marriage-person/delete-multi`, 'DELETE', { ids: selectedRows }).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        setSelectedRows([])
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
        setDeleteMulti(true);
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
  }

  const handleDelete = (id) => {
    console.log("single delete with id => ", id)
    setShowModal(true)
    setDeleteItem(id)
    setDeleteType('single')
  }

  const handleMultiDelete = () => {
    console.log("multi delete => ", selectedRows)
    setShowModal(true)
    setDeleteType('multi')
  }

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true))
    let query = `?limit=${itemsPerPage}&page=${currentPage}&name=${searchString}&person=${selectedPerson}`

    const getSpouseData = async () => {
      try {
        const response = await requestApi(`/marriage-person/search${query}`, 'GET');
        // console.log("response hungffff=> ", response.data.result)
        const lastPage = Math.ceil(response.data.result.count / itemsPerPage);
        setSpouseData(response.data.result.items);
        setTotalItems(response.data.result.count || 0);
        setNumOfPage(lastPage)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
        setDeleteMulti(false);
      } catch (error) {
        console.log(error)
        setDeleteMulti(false);
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      }
    }
    getSpouseData();
  }, [currentPage, selectedPerson, itemsPerPage, selectedRows, searchString, refresh, dispatch])

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {t('title:spouse_list')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to='/admin/dashboard'>{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item active">{t('title:spouse_list')}</li>
          </ol>

          <div className='mb-3'>
            <Link className='btn btn-sm btn-success me-2' to='/admin/spouse/add'><i className="fa fa-plus"></i> {t('button:add_new')}</Link>
            {selectedRows.length > 0 && <button type='button' className='btn btn-sm btn-danger' onClick={handleMultiDelete}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>}
          </div>
          <DataTable
            personOptionData={optionPerson}
            setSelectedPerson={setSelectedPerson}
            name={t('table:title.spouse_list')}
            data={spouseData}
            columns={columns}
            numOfPage={numOfPage}
            currentPage={currentPage}
            onRefreshPage={setRefresh}
            onPageChange={setCurrentPage}
            onChangeItemsPerPage={setItemsPerPage}
            totalNumberItems={totalItems}
            onKeySearch={(keyword) => {
              console.log("keyword in spouse list comp=> ", keyword)
              setSearchString(keyword)
            }}
            onSelectedRows={rows => {
              console.log("selected rows in uselist=> ", rows)
              setSelectedRows(rows)
            }}
            deleteMulti={deleteMulti}
          />
        </div>
      </main>
      <Modal show={showModal} onHide={() => setShowModal(false)} size='sm'>
        <Modal.Header closeButton>
          {/* <Modal.Title>Confirmation</Modal.Title> */}
          <Modal.Title>{t('table:modal.confirmation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Are you sure want to delete? */}
          {t('table:modal.want_delete')}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>{t('button:close')}</Button>
          <Button className='btn-danger' onClick={requestDeleteApi}>{t('table:button.delete')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SpouseList