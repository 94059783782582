import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DataTable from './components/DataTable'
import { Button, Modal } from 'react-bootstrap'

import globalLoading from '../../../redux/reducers/globalLoading';
import requestApi from '../../../api/api';
import { formatDate, formatDateTime } from '../../../helpers/common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const UserList = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([])
  const [numOfPage, setNumOfPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [searchString, setSearchString] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [totalItems, setTotalItems] = useState(0);

  const [deleteItem, setDeleteItem] = useState(null)
  const [deleteType, setDeleteType] = useState('single')
  const [deleteMulti, setDeleteMulti] = useState(false);

  const [showModal, setShowModal] = useState(false)
  const [refresh, setRefresh] = useState(Date.now())
  const { t } = useTranslation(['title', 'table', 'sidebar', 'button']);

  const columns = [
    {
      name: t('table:table.index'),
    },
    {
      // name: "Full name",
      name: t('table:table.full_name'),
      element: row => row.full_name
    },
    {
      name: "Email",
      element: row => row.email
    },
    {
      // name: "User name",
      name: t('table:table.username'),
      element: row => row.username
    },
    {
      // name: "Gender",
      name: t('table:table.gender'),
      element: row => row.gender
    },
    {
      // name: "Role",
      name: t('table:table.role'),
      element: row => row.role
    },
    {
      // name: "Birthday",
      name: t('table:table.date_birth'),
      element: row => row.birthday ? formatDate(row.birthday) : ''
    },
    {
      // name: "Created at",
      name: t('table:table.created_at'),
      element: row => formatDateTime(row.created_at)
    },
    {
      // name: "Updated at",
      name: t('table:table.updated_at'),
      element: row => formatDateTime(row.updated_at)
    },
    {
      // name: "Actions",
      name: t('table:table.action'),
      element: row => (
        <>
          {/* <button type="button" className="btn btn-sm btn-warning me-1"><i className="fa fa-pencil"></i> Edit</button> */}
          <button type="button" className="btn btn-sm btn-danger me-1" onClick={() => handleDelete(row._id)}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>
        </>
      )
    }
  ]

  const requestDeleteApi = () => {
    if (deleteType === 'single') {
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/user/${deleteItem}`, 'DELETE', []).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
    else {
      dispatch(globalLoading.actions.CONTROL_LOADING(true))
      requestApi(`/user/delete-multi`, 'DELETE', { ids: selectedRows }).then(response => {
        setShowModal(false)
        setRefresh(Date.now())
        setSelectedRows([])
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
        setDeleteMulti(true);
      }).catch(err => {
        console.log(err)
        setShowModal(false)
        dispatch(globalLoading.actions.CONTROL_LOADING(false))
      })
    }
  }

  const handleDelete = (id) => {
    console.log("single delete with id => ", id)
    setShowModal(true)
    setDeleteItem(id)
    setDeleteType('single')
  }

  const handleMultiDelete = () => {
    console.log("multi delete => ", selectedRows)
    setShowModal(true)
    setDeleteType('multi')
  }

  useEffect(() => {
    dispatch(globalLoading.actions.CONTROL_LOADING(true))
    let query = `?limit=${itemsPerPage}&page=${currentPage}&search=${searchString}`
    requestApi(`/user/search${query}`, 'GET', []).then(response => {
      console.log("response=> ", response)
      const lastPage = Math.ceil(response.data.result.count / itemsPerPage);
      setUsers(response.data.result.items)
      setTotalItems(response.data.result.count || 0);
      setNumOfPage(lastPage)
      dispatch(globalLoading.actions.CONTROL_LOADING(false))
      setDeleteMulti(false);
    }).catch(err => {
      console.log(err)
      dispatch(globalLoading.actions.CONTROL_LOADING(false))
      setDeleteMulti(false);
    })
  }, [currentPage, itemsPerPage, selectedRows, searchString, refresh])

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid px-4">
          <h1 className="mt-4">
            {t('title:user_list')}
          </h1>
          <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item"><Link to="/admin/dashboard">{t('sidebar:dashboard')}</Link></li>
            <li className="breadcrumb-item active">{t('title:user_list')}</li>
          </ol>

          <div className='mb-3'>
            {/* <button type='button' className='btn btn-sm btn-success me-2'><i className="fa fa-plus"></i> Add new</button> */}
            <Link className='btn btn-sm btn-success me-2' to='/admin/users/add'><i className="fa fa-plus"></i> {t('button:add_new')}</Link>
            {selectedRows.length > 0 && <button type='button' className='btn btn-sm btn-danger' onClick={handleMultiDelete}><i className="fa fa-trash"></i> {t('table:button.delete')}</button>}
          </div>
          <DataTable
            // name="List Users"
            name={t('title:user_list')}
            data={users}
            columns={columns}
            numOfPage={numOfPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            onChangeItemsPerPage={setItemsPerPage}
            totalNumberItems={totalItems}
            onKeySearch={(keyword) => {
              console.log("keyword in user list comp=> ", keyword)
              setSearchString(keyword)
            }}
            onSelectedRows={rows => {
              console.log("selected rows in uselist=> ", rows)
              setSelectedRows(rows)
            }}
            deleteMulti={deleteMulti}
          />
        </div>
      </main>
      <Modal show={showModal} onHide={() => setShowModal(false)} size='sm'>
        <Modal.Header closeButton>
          {/* <Modal.Title>Confirmation</Modal.Title> */}
          <Modal.Title>{t('table:modal.confirmation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Are you sure want to delete? */}
          {t('table:modal.want_delete')}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>{t('button:close')}</Button>
          <Button className='btn-danger' onClick={requestDeleteApi}>{t('table:button.delete')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}


export default UserList