import { toast } from 'react-toastify';

export const toastSuccess = (text, autoClose) => {
  toast.success(`${text}`, {
    position: "top-center",
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
export const toastError = (text, autoClose) => {
  toast.error(`${text}`, {
    position: "top-center",
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

